import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { postCall, getCall } from "../../utils/apicalls";
import { authenticate } from "../slices/authSlice";

const initialState = {
  status: "idle",
  error: "",
  id: "",
  name: "",
  email: "",
  age: "",
  image:'',
  city: "",
  state: "",
  country: "",
  bio: "",
  instagram: "",
  followers: "",
  following: "",
  twitter: "",
  snapchat: "",
  youtube: "",
  linkedin: "",
  facebook: "",
  interests: [],
};

export const register = createAsyncThunk(
  "influencer/register",
  async (body, { dispatch }) => {
    const response = await postCall("/auth/register", body);

    dispatch(
      authenticate({
        token: response.data.token,
        userType: "influencer",
        userId: response.id,
        userName: response.name,
        email: response.email,
      })
    );

    return response;
  }
);

export const login = createAsyncThunk(
  "influencer/login",
  async (body, { dispatch }) => {
    const response = await postCall("/auth/login", body);

    dispatch(
      authenticate({
        token: response.data.token,
        userType: "influencer",
        userId: response.id,
        userName: response.name,
        email: response.email,
      })
    );

    return response;
  }
);

export const update = createAsyncThunk("influencer/update", async (body) => {
  const response = await postCall("/saveCreateUserProfile", body);

  return response.returnProfile;
});

export const getProfile = createAsyncThunk(
  "influencer/getProfile",
  async (id) => {
    const response = await getCall(`/getUserProfile/${id}`);

    return response;
  }
);

export const getInstagramData = createAsyncThunk(
  "influencer/getInstagramData",
  async (body) => {
    const response = await postCall("/retrieveInstagramData", body);

    return response;
  }
);

const inlfuencerSlice = createSlice({
  name: "influencer",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(register.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.id = action.payload.id;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.id = action.payload.id;
      })
      .addCase(update.pending, (state, action) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = "idle";

        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          const data = action.payload;

          state.age = data.age;
          state.city = data.city;
          state.state = data.state;
          state.country = data.country;
          state.bio = data.bio;
          state.interests = data.interests;
          state.instagram = data.instagram;
          state.twitter = data.twitter;
          state.snapchat = data.snapchat;
          state.youtube = data.youtube;
          state.linkedin = data.linkedin;
          state.facebook = data.facebook;
        }
      })
      .addCase(getProfile.pending, (state, action) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          const data = action.payload;

          state.id = data.user_id;
          state.name = data.name;
          state.age = data.age;
          state.city = data.city;
          state.state = data.state;
          state.country = data.country;
          state.bio = data.bio;
          state.interests = data.interests;
          state.instagram = data.instagram;
          state.twitter = data.twitter;
          state.snapchat = data.snapchat;
          state.youtube = data.youtube;
          state.linkedin = data.linkedin;
          state.facebook = data.facebook;
          state.image = data.image;
        }
      })
      .addCase(getInstagramData.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          const data = action.payload;

          state.followers = data.followers;
          state.following = data.followed_by;
        }
      });
  },
});

export default inlfuencerSlice.reducer;
