import React from "react";
import {
  makeStyles,
  Hidden,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
} from "@material-ui/core";

import ResponsiveCard from "../../../layout/papers/ResponsiveCard";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  card: { padding: 24 },
  divider: { margin: "24px 0" },
  title: { marginBottom: 4 },
  subtitle: { marginBottom: 20 },
  listItem: {
    backgroundColor: theme.palette.secondaryBackground,
    borderRadius: 4,
    "@media (max-width: 600px)": { backgroundColor: "white" },
  },
}));

export default function AccountSetupWidget() {
  const classes = useStyles();
  const {instagram} = useSelector(
    ({ influencer }) => influencer
  );
  const checked=instagram?"checked":"";
  return (
    <ResponsiveCard>
      <Hidden smUp>
        <Divider className={classes.divider} />
      </Hidden>

      <Typography variant="h4" className={classes.title}>
        Account Setup Complete!
      </Typography>
      <Typography className={classes.subtitle}>
        (0 of 1 Steps Remaining)
      </Typography>

      {["Connect Instagram"].map((text, i) => (
        <List key={i}>
          <ListItem dense className={classes.listItem}>
            <ListItemIcon>

              <Checkbox edge="start" color="primary" disableRipple checked={checked}  />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        </List>
      ))}
    </ResponsiveCard>
  );
}
