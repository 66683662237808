import React from "react";
import {
  makeStyles,
  styled,
  Button,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import StepCard from "../../../../layout/Step/StepCard";
import FormikTextField from "../../../../../components/layout/forms/FormikTextField";
import { register as companyRegister } from "../../../../../redux/slices/companySlice";
import { register as memberRegister } from "../../../../../redux/slices/memberSlice";

const TextField = styled(FormikTextField)(spacing);

const useStyles = makeStyles(() => ({
  select: { width: "100%" },
  button: { marginLeft: "auto", marginTop: 60 },
}));

export default function AccountInfo({ notRegistered }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);

  if (isAuthenticated) {
    history.push(notRegistered ? "details" : "enter-code");
  }

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={Yup.object({
        firstName: Yup.string().max(255).required("Required"),
        lastName: Yup.string().max(255).required("Required"),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Required"),
        password: Yup.string()
          .min(8, "Must be at least 8 characters")
          .max(255)
          .required("Required"),
        confirmPassword: Yup.string().when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          ),
        }),
      })}
      onSubmit={async (
        { firstName, lastName, email, password },
        { setErrors, setSubmitting, setStatus }
      ) => {
        try {

          if (notRegistered) {

            await dispatch(
              companyRegister({
                user_name: `${firstName} ${lastName}`,
                company_name: `${firstName} ${lastName}`,
                email: email,
                password: password,
                user_type: "company"
              })
            );
          } else {
            await dispatch(
              memberRegister({
                name: `${firstName} ${lastName}`,
                email: email,
                password: password,
                user_type: "influencer"
              })
            );
          }

          
        } catch (err) {
          setSubmitting(false);
          setStatus({ success: false });
          setErrors({ submit: "An error ocurred" });
        }
      }}
    >
      {({ isSubmitting, errors }) => (
        <Form>
          <StepCard title="Create Your Account" loading={isSubmitting}>
            <Grid container>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    name="firstName"
                    type="text"
                    label="First Name"
                    fullWidth
                    variant="outlined"
                    mb={3}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    name="lastName"
                    type="text"
                    label="Last Name"
                    fullWidth
                    variant="outlined"
                    mb={3}
                  />
                </Grid>
              </Grid>

              <Field
                component={TextField}
                name="email"
                type="text"
                label="Email Address"
                fullWidth
                variant="outlined"
                mb={3}
              />

              <Field
                component={TextField}
                name="password"
                type="password"
                label="Password"
                fullWidth
                variant="outlined"
                mb={3}
              />

              <Field
                component={TextField}
                name="confirmPassword"
                type="password"
                label="Confirm Password"
                fullWidth
                variant="outlined"
              />

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
                fullWidth={isMobile}
              >
                Next
              </Button>
            </Grid>

            {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}
          </StepCard>
        </Form>
      )}
    </Formik>
  );
}
