import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Typography, Grid } from "@material-ui/core";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  container: { margin: "120px 0 40px" },
  sectionLeft: { marginRight: "auto", marginTop: 40 },
  sectionRight: { marginLeft: "auto", marginTop: 40 },
  icon: { width: "auto", height: 72, color: "#444444" },
}));

export default function LandingFeatures({ features }) {
  const classes = useStyles();

  return (
    <Grid container justify="center" spacing={3} className={classes.container}>
      <Grid container justify="center" item xs={12}>
        <Typography variant="h3">Our Features</Typography>
      </Grid>

      {features.map(({ Icon, title, description }, i) => (
        <Fragment key={i}>
          {i % 2 ? (
            <Grid
              item
              xs={12}
              md={10}
              container
              className={classes.sectionRight}
            >
              <Grid item xs={10} container justify="flex-end">
                <Typography variant="h6" align="right">
                  {title}
                </Typography>
                <Typography align="right">{description}</Typography>
              </Grid>
              <Grid item xs={2} container justify="center">
                <Icon className={classes.icon} />
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              md={10}
              container
              key={i}
              className={classes.sectionLeft}
            >
              <Grid item xs={2} container justify="center">
                <Icon className={classes.icon} />
              </Grid>
              <Grid item xs={10} container>
                <Typography variant="h6">{title}</Typography>
                <Typography>{description}</Typography>
              </Grid>
            </Grid>
          )}
        </Fragment>
      ))}
    </Grid>
  );
}
