import React from "react";
import {
  makeStyles,
  styled,
  Button,
  Grid as MuiGrid,
  Typography as MuiTypography,
  useMediaQuery,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import defaultImage from "../../../assets/images/default-profile-picture.png";

import StepCard from "../../layout/Step/StepCard";
import Moment from "react-moment";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: "auto",
    marginTop: 20,
  },
  uploadButton: { height: 56 },
  card: { padding: 32 },
  image: {
    height: 200,
    width: 200,
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  },
}));

export default function ContractSummary() {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const history = useHistory();
  const contractInfo = useSelector(({ contract }) => contract);
  console.log('contract', contractInfo);
  const user = useSelector(({ auth }) => auth.user);

  const handleClick = () => {
    history.push(`/contract/finalize/${contractInfo.id}`);
  };

  const displayDate = (date) => {
    return (
      date === null ? "" :
        <>
          <Moment format="MM/DD/YYYY">
            {date}
          </Moment>
        </>)
  }

  return (
    <StepCard cardClass={classes.card}>
      <Grid container direction="column">
        <Typography variant="h6">Description</Typography>
        <Typography mb={3}>
          {contractInfo.description}
        </Typography>
      </Grid>

      <Grid
        container
        direction={isMobile ? "column" : "row"}
        justify="space-between"
        mb={3}
        spacing={1}
      >
        <Grid item>
          <Typography variant="h6">Deadline</Typography>
          <Typography>{displayDate(contractInfo.deadline)}</Typography>

        </Grid>
        <Grid item>
          <Typography variant="h6">Other Party</Typography>
          <Typography>{contractInfo.player_name}</Typography>
          <img src={contractInfo.Player.image ? contractInfo.Player.image!=='/assets/images/default.png'?contractInfo.Player.image:defaultImage : defaultImage} alt="" className={classes.profileimage} />
        </Grid>
        <Grid item>
          <Typography variant="h6">Payment</Typography>
          <Typography>{contractInfo.payment_amount}</Typography>
        </Grid>
      </Grid>

      <Grid container direction="column" mb={5}>
        <Typography variant="h6" gutterBottom>
          Post Media Included
        </Typography>
        <img src={contractInfo.image?contractInfo.image:defaultImage} className={classes.image} />
      </Grid>

      <Grid container justify="flex-end">
        <Button variant="contained" color="secondary" onClick={handleClick}>
          Submit
        </Button>
      </Grid>
    </StepCard>
  );
}
