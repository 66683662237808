import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid as MuiGrid,
  InputAdornment,
  List,
  ListItem,
  makeStyles,
  Slider,
  styled,
  TextField,
  Typography as MuiTypography,
  useMediaQuery,
  Popover
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Link, useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useSelector } from "react-redux";
import { getLocation } from "../../../utils/common";
import Layout from "../../layout/Internal";
import { getCall, postCall } from "../../../utils/apicalls";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import defaultImage from "../../../assets/images/default-profile-picture.png";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles((theme) => ({
  textfield: {
    backgroundColor: "white",
    marginRight: 24,
    display: "inline",
    "@media (max-width: 600px)": { marginRight: 0, marginBottom: 12 },
  },
  // dropdownButton:{
  //   height: "52px",
  //   marginTop: "5px",
  // },
  listHeader: {
    minHeight: 56,
    backgroundColor: `${theme.palette.primary.main} !important`,
    "& p": { fontWeight: 600 },
  },
  listItem: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 4,
    marginBottom: 4,
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  },
  avatar: { minWidth: 56, width: "fit-content", padding: "4px 20px" },
  image: { width: 50, height: 50, borderRadius: "100%"},
  interests: { width: 176, padding: "24px 20px" },
  itemSection: { width: 148, padding: "4px 20px" },
  action: { width: "fit-content", marginLeft: "auto" },
  selectContainer: { "@media (max-width: 600px)": { width: "100%" } },
  select: {
    minWidth: 200,
    backgroundColor: "white",
    "@media (max-width: 600px)": { width: "100%" },
  },
  selectLabel: {
    "&.Mui-focused": {
      color: theme.palette.label.main,
    },
  },
  buttonsContainer: { padding: "4px 12px 12px" },
  dialogButton: { margin: 4 },
  typography: {
    padding: theme.spacing(2),
  },
  gridContainer: {
    display: "inline"
  },
  secondarybutton: {
    boxShadow: 'none',
    border: '1px solid',
    backgroundColor: 'white',
    borderColor: 'grey',
    color: 'grey'
  }
}));

export default function Members() {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const userAuthId = useSelector(({ auth }) => auth.user.id);
  const userAuthName = useSelector(({ auth }) => auth.user.name);
  const userType = useSelector(({ auth }) => auth.user.type);

  //List members
  const [members, setMembers] = useState([]);
  const [search, setSearch] = useState({ name: "", follower_min: "", follower_max: "", interests: "", location: "", age_min: '', age_max: '' });
  const [searchedMembers, setSearchedMembers] = useState([]);
  const [memberName, setMemberName] = useState('');

  const getMembers = async () => {

    const gottenMembers = await getCall("/getAllUserProfiles?search=" + "");
    const response = await getCall(`/getInterests/${userAuthId}`);

    const allmembers = Object.keys(gottenMembers).map((key, index) => {

      const expressed = response?.data.some(item => item.UserId === gottenMembers[key].user_id);

      return { ...gottenMembers[key], expressed };
    });

    setMembers(allmembers);
    setSearchedMembers(allmembers);
  };

  useEffect(() => {
    getMembers();
    setSearch({ name: "", follower_min: '', follower_max: '', interests: "", location: "", age_min: '', age_max: '' });
  }, []);

  //Dialog
  const [open, setOpen] = useState(false);
  const [memberId, setMemberId] = useState(1);

  const toggleOpen = (user_id = null, memberName = null) => {
    setOpen(!open);
    setMemberId(user_id);
    setMemberName(memberName);
  };

  const handleExpressInterest = async () => {
    // data: { status: "unread", senderName: userAuthName, userName: name },

    await postCall("/saveCreateInterest", {
      UserId: memberId,
      SenderId: userAuthId,
      data: { status: "unread", senderName: userAuthName, userName: memberName },
    });

    postCall("/saveCreateNotification", {
      data: {
        UserId: userAuthId,
        PersonId: memberId,
        text: `${memberName}`,
        status: 'unread',
        type: 2,
        express_auth_type: 0
      },     
      profile_id: memberId
    });

    postCall("/saveCreateNotification", {
      data: {
        UserId: memberId,
        PersonId: userAuthId,
        text: `${userAuthName}`,
        status: 'unread',
        type: 2,
        express_auth_type: userType == 'company' ? 1 : 0
      },     
      profile_id: userAuthId
    });
    
    getMembers();
    // setExpressedInterest(true);
    toggleOpen();

  };
  //End - Dialog
  const searchMembers = () => {
    getMembers()
  };
  const ItemSection = (props) => (
    <Grid
      {...props}
      item
      container
      alignItems="center"
      className={props.className ? props.className : classes.itemSection}
    />
  );

  const isEmpty = (param) => {
    if (param == null)
      return true;
    if (param == undefined)
      return true;
    if (param == "")
      return true;
    return false;
  };

  const displayField = (param, type = '') => {
    if (isEmpty(param))
      return "";
    if (type == 'interests') {
      return String(param).replaceAll('::', ' ').replaceAll(' ', ', ');
    }

    return param;
  }

  function valuetext(value) {
    return `${value}`;
  }

  const marksAge = [
    {
      value: 1,
      label: 'Age: min-1',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 200,
      label: 'max-200',
    },
  ];

  const marksInterest = [
    {
      value: 1,
      label: 'Interests: min-1',
    },
    {
      value: 100,
      label: '100',
    },
    {
      value: 1000,
      label: 'max-1000',
    },
  ];

  const searchFieldRange = ({ label, name }) => {
    return (
      <FormControl variant="outlined" className={classes.formControl} style={{ width: 120 }}>
        {/* <Typography id={"slider-range" + name} gutterBottom>
          {label}
        </Typography> */}
        <Slider
          defaultValue={80}
          getAriaValueText={valuetext}
          aria-labelledby={"slider-range" + name}
          step={name === "age" ? 1 : 5}
          marks={name === "age" ? marksAge : marksInterest}
          valueLabelDisplay="on"
        />
      </FormControl>
    );
  }

  const isContainString = (item, name) => {
    var itemField = name === "location" ? getLocation(item.city, item.state, item.country) : item[name];

    if (!isEmpty(search[name])) {
      if (isEmpty(itemField))
        return false;
      if ((String(itemField).toLowerCase()).indexOf(String(search[name]).toLowerCase()) < 0)
        return false;
    }
    return true;
  }

  const compareSearchNumber = (item, name1, name2) => {
    var itemField = item[name1];
    var searchField = search[name2];
    if (isEmpty(searchField))
      return true;
    if (isEmpty(itemField))
      return false;
    return itemField == searchField;
  }

  const doSearch = () => {
    var searched = Object.values(members).filter(item => {
      if (!compareSearchNumber(item, "instagram_followers", "follower_min"))
        return false;
      if (!compareSearchNumber(item, "instagram_followers", "follower_max"))
        return false;
      if (!isContainString(item, "name"))
        return false;
      if (!isContainString(item, "interests"))
        return false;
      if (!isContainString(item, "location"))
        return false;
      if (!compareSearchNumber(item, "age", "age_min"))
        return false;
      if (!compareSearchNumber(item, "age", "age_max"))
        return false;
      return true;
    });
    setSearchedMembers(searched);
  };

  const searchFieldText = ({ label, name, type = 'text' }) => {
    return (
      <Grid
        item
        xs={isMobile ? 12 : 2}
        container
        className={classes.gridContainer}
      >
        <FormControl variant="outlined" className={classes.formControl} style={{ width: "100%" }}>
          <TextField
            type={type}
            style={{ width: "100%" }}
            fullWidth={true}
            className={classes.textfield}
            variant="outlined"
            value={search[name]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            label={label}
            onChange={(event) => {
              setSearch({ ...search, [name]: event.target.value });
            }}
          />
        </FormControl>
      </Grid>
    );
  }

  const GridEmptyItem = () => (
    <Grid
      item
      xs={isMobile ? 6 : 2}
      container
      alignItems="center"
    ></Grid>
  );
  const [ageAnchorEl, setAgeAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const popoverOpen = Boolean(anchorEl);
  const agePopoverOpen = Boolean(ageAnchorEl);

  const id = popoverOpen ? 'simple-popover' : undefined;
  const ageId = agePopoverOpen ? 'simple-popover' : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const ageHandleClick = (event) => {
    setAgeAnchorEl(event.currentTarget);
  };
  const ageHandleClose = () => {
    setAgeAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Layout>
        <Typography variant="h3">Explore Members</Typography>
        {isMobile ?
          <Grid container direction="row" mt={1} mb={1} spacing={1}>
            {searchFieldText({ label: "Name", name: "name" })}
            <Button aria-describedby={id} fullWidth={isMobile} variant="contained" endIcon={    <ArrowDropDownIcon />} color="primary" onClick={handleClick}>
              Followers
            </Button>
            {/* {GridEmptyItem()} */}
            {/* {searchFieldText({ label: "Followers(min)", name: "follower_min", type: "number" })}
            {searchFieldText({ label: "Followers(max)", name: "follower_max", type: "number" })} */}
            {searchFieldText({ label: "Interests", name: "interests" })}
            <Button aria-describedby={ageId} fullWidth={isMobile} variant="contained" color="primary" endIcon={    <ArrowDropDownIcon />} onClick={ageHandleClick}>
              Age
            </Button>
            <Grid
              item
              xs={isMobile ? 6 : 2}
              container
              alignItems="center"
            ></Grid>
            {searchFieldText({ label: "Location", name: "location" })}
            {/* {GridEmptyItem()} */}
            {/* {searchFieldText({ label: "Age(min)", name: "age_min", type: "number" })}
            {searchFieldText({ label: "Age(max)", name: "age_max", type: "number" })} */}

            <Grid
              item
              xs={isMobile ? 12 : 2}
              container
              alignItems="center"
            >
              <Button variant="contained" color="primary" fullWidth={isMobile} onClick={() => doSearch()} >
                Search
            </Button>
            </Grid>
          </Grid>
          :
          <Grid container direction="row" mt={1} mb={1} spacing={1}>
           
            {searchFieldText({ label: "Name", name: "name" })}
            <Grid
              item
              xs={isMobile ? 12 : 2}
              container
              alignItems="center"
            >
            <Button aria-describedby={id} variant="contained" endIcon={    <ArrowDropDownIcon />} onClick={handleClick} className={classes.secondarybutton}>
              Followers
            </Button>
            </Grid>

            {searchFieldText({ label: "Interests", name: "interests" })}
            <Grid
              item
              xs={isMobile ? 12 : 2}
              container
              alignItems="center"
            >
              <Button aria-describedby={ageId} variant="contained" className={classes.secondarybutton} endIcon={    <ArrowDropDownIcon />}  onClick={ageHandleClick}>
                Age
              </Button>
            </Grid>

            {searchFieldText({ label: "Location", name: "location" })}
            <Grid
              item
              xs={isMobile ? 12 : 2}
              container
              alignItems="center"
            >
              <Button variant="contained" color="primary" fullWidth={isMobile}  onClick={() => doSearch()} >
                Search
              </Button>
            </Grid>

            <Grid
              item
              xs={isMobile ? 12 : 2}
              container
              alignItems="center"
            >
            </Grid>
            <Grid
              item
              xs={isMobile ? 12 : 2}
              container
              alignItems="center"
            >
            </Grid>

          </Grid>
        }
        <List>
          {!isMobile && (
            <ListItem className={`${classes.listItem} ${classes.listHeader}`}>
              <Grid container direction="row" spacing={2}>
                <ItemSection className={classes.avatar}></ItemSection>
                <ItemSection>
                  <Typography>Name</Typography>
                </ItemSection>
                <ItemSection>
                  <Typography>Platforms</Typography>
                </ItemSection>
                <ItemSection>
                  <Typography>Followers</Typography>
                </ItemSection>
                <ItemSection className={classes.interests}>
                  <Typography>Interests</Typography>
                </ItemSection>
                <ItemSection>
                  <Typography>Location</Typography>
                </ItemSection>
                <ItemSection>
                  <Typography>Age</Typography>
                </ItemSection>
              </Grid>
            </ListItem>
          )}

          {Object.values(searchedMembers).map(({ user_id: userId, id, name, expressed,
            instagram_followers, instagram_followings, interests,
            city, country, state, age, image }) => (
            <ListItem className={classes.listItem} key={userId}>
              <Grid container direction="row" spacing={2}>
                <ItemSection className={classes.avatar}>
                  <img src={image ? image!=='/assets/images/default.png'?image:defaultImage : defaultImage} className={classes.image} alt="" />
                </ItemSection>
                <ItemSection>
                  <Link to={`/member-profile/${userId}`}>
                    <Typography>{name}</Typography>
                  </Link>
                </ItemSection>
                {!isMobile && (
                  <>
                    <ItemSection>
                      <Typography>Instagram</Typography>
                    </ItemSection>
                    <ItemSection>
                      <Typography>{displayField(instagram_followers)}</Typography>
                    </ItemSection>
                    <ItemSection className={classes.interests}>
                      <Typography>{displayField(interests, 'interests')}</Typography>
                    </ItemSection>
                    <ItemSection>
                      <Typography>{getLocation(city, state, country)}</Typography>
                    </ItemSection>
                    <ItemSection>
                      <Typography>{displayField(age)}</Typography>
                    </ItemSection>

                  </>
                )}
                <ItemSection className={classes.action}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => toggleOpen(userId, name)}
                    disabled={expressed}
                  >
                    {expressed ? "Interest Expressed" : "Express Interest"}
                  </Button>
                </ItemSection>
              </Grid>
            </ListItem>
          ))}
        </List>
      </Layout>

      <Dialog open={open} onClose={toggleOpen}>
        <DialogTitle>Are you sure?</DialogTitle>
        <Grid container justify="center" className={classes.buttonsContainer}>
          <Button
            onClick={handleExpressInterest}
            color="secondary"
            variant="contained"
            className={classes.dialogButton}
          >
            Yes
          </Button>

          <Button
            onClick={toggleOpen}
            color="secondary"
            variant="contained"
            className={classes.dialogButton}
          >
            No
          </Button>
        </Grid>
      </Dialog>
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {searchFieldText({ label: "Followers(min)", name: "follower_min", type: "number" })}
        {searchFieldText({ label: "Followers(max)", name: "follower_max", type: "number" })}
      </Popover>
      <Popover
        id={id}
        open={agePopoverOpen}
        anchorEl={ageAnchorEl}
        onClose={ageHandleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div>
          {searchFieldText({ label: "Age(min)", name: "age_min", type: "number" })}
          {searchFieldText({ label: "Age(max)", name: "age_max", type: "number" })}
        </div>
      </Popover>
    </>
  );
}
