import React, { useEffect, useState } from "react";
import {
  makeStyles,
  styled,
  Button,
  Grid as MuiGrid,
  Typography as MuiTypography,
  useMediaQuery,
  TextField,
  InputAdornment,
  List,
  ListItem,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useHistory, Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";

import Layout from "../../layout/Internal";
import { getCall } from "../../../utils/apicalls";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import LinearProgress from '@material-ui/core/LinearProgress';

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles((theme) => ({
  textfield: {
    backgroundColor: "white",
    marginRight: 24,
    "@media (max-width: 600px)": { marginRight: 0, marginBottom: 12 },
  },
  listHeader: {
    minHeight: 56,
    backgroundColor: `${theme.palette.primary.main} !important`,
    "& p": { fontWeight: 600 },
  },
  listItem: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 4,
    marginBottom: 4,
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  },
  avatar: { minWidth: 56, width: "fit-content", padding: "4px 20px" },
  status: { width: 176, padding: "24px 20px" },
  itemSection: { width: 148, padding: "4px 20px", textTransform: "capitalize" },
  action: { width: "fit-content", marginLeft: "auto" },
  formControl: { minWidth: 160, marginBottom: 24 },
  label: {
    "&.Mui-focused": {
      color: theme.palette.label.main,
    },
  },
}));

export default function ContractSummary() {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const history = useHistory();
  const id = useSelector(({ auth }) => auth.user.id);
  const userAuthId = useSelector(({ auth }) => auth.user.id);
  const { type } = useSelector(({ auth }) => auth.user);

  const handleClick = (statusParam) => () => {
    history.push(`/contract/${statusParam}`);
  };

  //Select input
  const [selectedStatus, setSelectedStatus] = useState("All");

  const [contracts, setContracts] = useState([]);

  const [previousContracts, setPreviousContracts] = useState([]);
  const [search, setSearch] = useState('');

  const [status, setStatus] = useState("idle");

  const handleChange = (e) => {
    setSelectedStatus(e.target.value);

    if (e.target.value == 'all') {
      setContracts(previousContracts);
    }

    let constractsData = previousContracts.filter((item)=> {
      console.log('item.status', item.status, String(e.target.value).toLowerCase());
      if ((item.status).includes(String(e.target.value).toLowerCase()) ) {
        if (search == '') {
          return item;
        } 

        if ( item.player_name.toLowerCase().includes(String(search).toLowerCase())) {
          return item;
        }
        
      };
    });

    setContracts(constractsData);
  };

  //End - Select input
  useEffect(() => {
    const getContracts = async () => {
      const contracts = await getCall(`/getContracts/${userAuthId}`);
      setContracts(contracts.data)
      setPreviousContracts(contracts.data)
      setStatus("idle");
    };
    setStatus("loading");
    getContracts();
  }, []);
  const searchClick = () => {

    let constractsData = previousContracts.filter((item)=> {
      if ( item.player_name.toLowerCase().includes(String(search).toLowerCase())) {
        return item;
      }
    });

    setContracts(constractsData)

  };
  const ItemSection = (props) => (
    <Grid
      {...props}
      item
      container
      alignItems="center"
      className={props.className ? props.className : classes.itemSection}
    />
  );
  const displayDate = (date) => {
    return (
      date === null ? "" :
        <>
          <Moment format="MM/DD/YYYY">
            {date}
          </Moment>
          <br />
          <Moment format="HH:mm A">
            {date}
          </Moment>
        </>)
  }

  return (
    <Layout>
      <Typography variant="h3">All Contracts</Typography>
      <Grid container direction="row" alignItems="center" mb={3}>
        <TextField onChange={(e) => setSearch(e.target.value)}
          fullWidth={isMobile}
          className={classes.textfield}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <Button onClick={() => searchClick()} variant="contained" color="primary" fullWidth={isMobile}>
          Search
        </Button>
      </Grid>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="select-label" className={classes.label}>
          Status
        </InputLabel>
        <Select
          labelId="select-label"
          value={selectedStatus}
          onChange={handleChange}
          label="Status"
        >
          {["All", "Active", "Pending", "Completed"].map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <List>
        {!isMobile && (
          <ListItem className={`${classes.listItem} ${classes.listHeader}`}>
            <Grid container direction="row" spacing={2}>
              <ItemSection className={classes.avatar}></ItemSection>
              <ItemSection>
                <Typography>Other Party</Typography>
              </ItemSection>
              <ItemSection>
                <Typography>Deadline</Typography>
              </ItemSection>
              <ItemSection>
                <Typography>Payment Amount</Typography>
              </ItemSection>
              <ItemSection className={classes.status}>
                <Typography>Status</Typography>
              </ItemSection>
              <ItemSection>
                <Typography>Date Offered</Typography>
              </ItemSection>
              <ItemSection>
                <Typography></Typography>
              </ItemSection>
            </Grid>
          </ListItem>
        )}
        {status === "loading" &&
          <div className={classes.loading}>
            <LinearProgress />
          </div>
        }
        {
          status !== "loading" &&
          Object.values(contracts).map(
            ({ player_id, company_id, company_name, player_name, deadline, payment_amount, status, createdAt, id }, i) => (
              <ListItem className={classes.listItem} key={i}>
                <Grid container direction="row" spacing={2}>
                  <ItemSection className={classes.avatar}>
                    <Avatar />
                  </ItemSection>
                  <ItemSection>
                    <Link to={'/member-profile/' + (type == "company" ? player_id : company_id)}>
                      <Typography>{type == "company" ?  player_name : company_name}</Typography>
                    </Link>
                  </ItemSection>
                  {!isMobile && (
                    <>
                      <ItemSection>
                        <Typography>
                          {displayDate(deadline)}
                        </Typography>
                      </ItemSection>
                      <ItemSection>
                        <Typography>{payment_amount}</Typography>
                      </ItemSection>
                      <ItemSection className={classes.status}>
                        <Typography>{status}</Typography>
                      </ItemSection>
                      <ItemSection>
                        <Typography>
                          {displayDate(createdAt)}
                        </Typography>
                      </ItemSection>
                    </>
                  )}
                  <ItemSection className={classes.action}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleClick(id)}
                    >
                      {isMobile ? "View" : "View Contract"}
                    </Button>
                  </ItemSection>
                </Grid>
              </ListItem>
            )
          )}
      </List>
    </Layout>
  );
}

const sampleList = [
  {
    otherParty: "John Smith",
    deadline: "Date",
    paymentAmount: "100",
    status: "active",
    dateOffered: "Date",
  },
  {
    otherParty: "John Smith",
    deadline: "Date",
    paymentAmount: "100",
    status: "pending",
    dateOffered: "Date",
  },
  {
    otherParty: "John Smith",
    deadline: "Date",
    paymentAmount: "100",
    status: "completed",
    dateOffered: "Date",
  },
  {
    otherParty: "John Smith",
    deadline: "Date",
    paymentAmount: "100",
    status: "active",
    dateOffered: "Date",
  },
  {
    otherParty: "John Smith",
    deadline: "Date",
    paymentAmount: "100",
    status: "pending",
    dateOffered: "Date",
  },
  {
    otherParty: "John Smith",
    deadline: "Date",
    paymentAmount: "100",
    status: "completed",
    dateOffered: "Date",
  },
  {
    otherParty: "John Smith",
    deadline: "Date",
    paymentAmount: "100",
    status: "active",
    dateOffered: "Date",
  },
  {
    otherParty: "John Smith",
    deadline: "Date",
    paymentAmount: "100",
    status: "pending",
    dateOffered: "Date",
  },
  {
    otherParty: "John Smith",
    deadline: "Date",
    paymentAmount: "100",
    status: "completed",
    dateOffered: "Date",
  },
];
