import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { postCall, putCall, getCall } from "../../utils/apicalls";
import { authenticate } from "./authSlice";

const initialState = {
  status: "idle",
  player_id: "",
  company_id: "",
  description: "",
  payment_amount: "",
  deadline: "",
  id: "",
  player_name: "",
  image: ''
};

export const save = createAsyncThunk(
  "contract/save",
  async (body, { dispatch }) => {
    const response = await postCall("/saveContract", body);
    return response;
  }
);


export const update = createAsyncThunk(
  "contract/update",
  async (body, { dispatch }) => {
    const response = await postCall("/updateSavedContract", body);
    return response;
  }
);

export const getContract = createAsyncThunk("contract/getContract", async (id) => {
  const response = await getCall(`/getContractByContractId/${id}`);
  return response;
});

const contractSlice = createSlice({
  name: "contract",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(save.fulfilled, (state, action) => {
        state.status = action.payload.status;
        state.player_id = action.payload.player_id;
        state.company_id = action.payload.company_id;
        state.description = action.payload.description;
        state.payment_amount = action.payload.payment_amount;
        state.deadline = action.payload.deadline;
        state.id = action.payload.id;
        state.player_name = action.payload.player_name;
        state.image = action.payload.image;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = action.payload.status;
        state.player_id = action.payload.player_id;
        state.company_id = action.payload.company_id;
        state.description = action.payload.description;
        state.payment_amount = action.payload.payment_amount;
        state.deadline = action.payload.deadline;
        state.id = action.payload.id;
        state.player_name = action.payload.player_name;
        state.image = action.payload.image;
      })
      .addCase(getContract.fulfilled, (state, action) => {
        state.status = action.payload.status;
        state.player_id = action.payload.player_id;
        state.company_id = action.payload.company_id;
        state.description = action.payload.description;
        state.payment_amount = action.payload.payment_amount;
        state.deadline = action.payload.deadline;
        state.id = action.payload.id;
        state.player_name = action.payload.player_name;
        state.image = action.payload.image;
      })
  },
});

export default contractSlice.reducer;
