import React from "react";
import {
  makeStyles,
  styled,
  Button,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import StepCard from "../../../../layout/Step/StepCard";
import FormikTextField from "../../../../../components/layout/forms/FormikTextField";
import { getCompanyAndId } from "../../../../../redux/slices/memberSlice";

const TextField = styled(FormikTextField)(spacing);

const useStyles = makeStyles(() => ({
  select: { width: "100%" },
  button: {
    marginLeft: "auto",
    marginTop: 60,
  },
}));

export default function EnterCode() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Formik
      initialValues={{
        code: "",
      }}
      validationSchema={Yup.object({
        code: Yup.string().max(255).required("Required"),
      })}
      onSubmit={async ({ code }, { setErrors, setStatus, setSubmitting }) => {
        try {
          const response = await dispatch(getCompanyAndId(code));

          if (
            response.type &&
            response.type === "member/getCompanyAndId/fulfilled"
          ) {
            history.push("confirm-company");
          }
        } catch (err) {
          setSubmitting(false);
          setStatus({ success: false });
          setErrors({ submit: "An error ocurred" });
        }
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <StepCard title="Enter Your Company Code" loading={isSubmitting}>
            <Grid container>
              <Field
                component={TextField}
                name="code"
                type="text"
                label="Company Code"
                fullWidth
                variant="outlined"
                mb={3}
              />

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
                fullWidth={isMobile}
              >
                Next
              </Button>
            </Grid>

            {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}
          </StepCard>
        </Form>
      )}
    </Formik>
  );
}
