import React from "react";
import { makeStyles, Grid, Stepper, Step, StepLabel } from "@material-ui/core";
import { Switch, Route, useRouteMatch, useLocation, useParams } from "react-router-dom";

import logo from "../../../assets/images/cc_logo.png";
import Layout from "../../layout/Step/Step";
import CreateContract from "./CreateContract";
import VerifyContract from "./VerifyContract";
import ContractSummary from "./ContractSummary";

const useStyles = makeStyles((theme) => ({
  header: { "@media (max-width: 600px)": { paddingTop: 80 } },
  image: {
    maxHeight: 60,
    maxWidth: 92,
    height: "auto",
    width: "auto",
    margin: "auto",
  },
  stepper: { backgroundColor: "transparent", marginTop: 12 },
  button: {
    position: "absolute",
    top: 20,
    left: 20,
  },
}));

export default function NewContract(props) {
  const { type, influencerId, contractId } = (props.location && props.location.state) || {};

  const classes = useStyles();
  const match = useRouteMatch();
  const { pathname } = useLocation();

  const activeStep =
    pathname === '/new-contract'
      ? 0
      : pathname === "/new-contract/verify-contract"
      ? 1
      : pathname === "/new-contract/contract-summary"
      ? 2
      : 2;

  return (
    <Layout backButton>
      <Grid
        container
        direction="column"
        justify="center"
        className={classes.header}
      >
        <img src={logo} alt="" className={classes.image} />

        <Stepper activeStep={activeStep} className={classes.stepper}>
          {["Create Contract", "Verify", "Summary"].map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Switch>
        <Route exact path={`${match.path}`}>
          <CreateContract {...props}/>
        </Route>
        <Route path={`${match.path}/verify-contract`}>
          <VerifyContract />
        </Route>
        <Route path={`${match.path}/contract-summary`}>
          <ContractSummary />
        </Route>
      </Switch>
    </Layout>
  );
}
