import React from "react";
import { useSelector } from "react-redux";

import InfluencerDashboard from "./InfluencerDashboard";
import CompanyDashboard from "./CompanyDashboard";

export default function Dashboard() {
  const { type } = useSelector(({ auth }) => auth.user);

  return (
    <>
      {type === "influencer" ? <InfluencerDashboard /> : <CompanyDashboard />}
    </>
  );
}
