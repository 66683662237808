import React from "react";
import {
  styled,
  Grid as MuiGrid,
  Button as MuiButton,
  useMediaQuery,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useHistory } from "react-router-dom";

import Step from "../../../components/layout/Step/Step";
import StepCard from "../../../components/layout/Step/StepCard";

const Grid = styled(MuiGrid)(spacing);
const Button = styled(MuiButton)(spacing);

export default function CompanyLoginQuestion() {
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const title = "Are you a company member or owner?";

  return (
    <Step>
      <StepCard innerTitle={!isMobile && title} title={isMobile && title}>
        <Grid
          container
          direction={isMobile ? "column" : "row"}
          justify="space-around"
          my={isMobile ? 0 : 6}
        >
          <Button
            variant="contained"
            color={isMobile ? "secondary" : "primary"}
            onClick={() => history.push("/company-owner-login")}
            mb={3}
          >
            Owner
          </Button>
          <Button
            variant="contained"
            color={isMobile ? "secondary" : "primary"}
            onClick={() => history.push("/company-member-login")}
            mb={3}
          >
            Member
          </Button>
        </Grid>
      </StepCard>
    </Step>
  );
}
