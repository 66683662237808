import React from "react";
import { makeStyles, Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: "24px 40px",
    "& button:first-child": { marginRight: 24 },
  },
}));

export default function LandingAuth({ company }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container justify="flex-end" className={classes.container}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push("/register")}
      >
        Sign Up
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push(company ? "/company-owner-login" : "/login")}
      >
        Login
      </Button>
    </Grid>
  );
}
