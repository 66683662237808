import { styled, LinearProgress as MuiProgress } from "@material-ui/core";

const CardProgress = styled(MuiProgress)({
  position: "absolute",
  width: "100%",
  bottom: 0,
  left: 0,
  borderRadius: "0 0 4px 4px",
});

export default CardProgress;
