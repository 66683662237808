import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { postCall, putCall, getCall } from "../../utils/apicalls";
import { authenticate } from "../slices/authSlice";

const initialState = {
  status: "idle",
  error: "",
  id: "",
  companyName: "",
  email: "",
  industry: "",
  website: "",
  bio: "",
  accessCode: "",
  image:'',
};

export const register = createAsyncThunk(
  "company/register",
  async (body, { dispatch }) => {
    const response = await postCall("/company/register", body);

    dispatch(
      authenticate({
        token: response.data.token,
        userType: "company",
        userId: response.id,
        userName: response.user_name,
        email: response.email,
      })
    );

    return response;
  }
);

export const login = createAsyncThunk(
  "company/login",
  async (body, { dispatch }) => {
    const response = await postCall("/company/login", body);

    dispatch(
      authenticate({
        token: response.data.token,
        userType: "company",
        userId: response.id,
        email: response.email,
        userName: response.user_name,
      })
    );

    return response;
  }
);

export const update = createAsyncThunk(
  "company/update",
  async ({ id, body }) => {
    const response = await putCall(`/company/${id}`, body);

    return response.returnCompany;
  }
);

export const getProfile = createAsyncThunk("company/getProfile", async (id) => {
  const response = await getCall(`/getCompanyByID/${id}`);

  return response.company;
});

export const getCompany = createAsyncThunk(
  "company/getCompany",
  async (code) => {
    const response = await getCall(`/company/${code}`);

    return response.company;
  }
);

const companySlice = createSlice({
  name: "company",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(register.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.id = action.payload.id;
        state.email = action.payload.email;
        state.accessCode = action.payload.access_code;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.id = action.payload.id;
        state.email = action.payload.email;
        state.accessCode = action.payload.access_code;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.companyName = action.payload.company_name;
        state.industry = action.payload.industry;
        state.website = action.payload.website_url;
        state.bio = action.payload.bio;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.id = action.payload.id;
        state.email = action.payload.email;
        state.accessCode = action.payload.access_code;
        state.companyName = action.payload.company_name;
        state.industry = action.payload.industry;
        state.website = action.payload.website_url;
        state.bio = action.payload.bio;
        state.image = action.payload.image;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.id = action.payload.id;
        state.email = action.payload.email;
        state.accessCode = action.payload.access_code;
        state.companyName = action.payload.company_name;
        state.industry = action.payload.industry;
        state.website = action.payload.website_url;
        state.bio = action.payload.bio;
      });
  },
});

export default companySlice.reducer;
