import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import TimerIcon from "@material-ui/icons/Timer";
import LockIcon from "@material-ui/icons/Lock";

const useStyles = makeStyles((theme) => ({
  container: { marginTop: 80 },
  itemsContainer: { marginTop: 12 },
  item: { width: "fit-content" },
  icon: {
    width: "auto",
    height: 72,
    color: "#444444",
  },
  bottomText: { marginTop: 20 },
}));

export default function LandingMission() {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      spacing={3}
      className={classes.container}
    >
      <Grid item>
        <Typography variant="h3">Our Mission</Typography>
      </Grid>

      <Grid item>
        <Typography variant="h5" align="center">
          To make the contract process more{" "}
        </Typography>
      </Grid>

      <Grid
        container
        justify="space-between"
        className={classes.itemsContainer}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.item}
        >
          <PeopleOutlineIcon className={classes.icon} />
          <Typography>Transparent</Typography>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.item}
        >
          <TimerIcon className={classes.icon} />
          <Typography>Efficient</Typography>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.item}
        >
          <LockIcon className={classes.icon} />
          <Typography>Secure</Typography>
        </Grid>
      </Grid>

      <Grid item className={classes.bottomText}>
        <Typography variant="h5" align="center">
          For all parties who do contracts on our platform
        </Typography>
      </Grid>
    </Grid>
  );
}
