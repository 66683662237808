import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Layout from "../../../layout/Step/Step";
import Question from "./Question";
import Details from "./registeredSteps/Details";
import Finalize from "./registeredSteps/Finalize";
import CompanyCode from "./registeredSteps/CompanyCode";
import AccountInfo from "./notRegisteredSteps/AccountInfo";
import EnterCode from "./notRegisteredSteps/EnterCode";
import ConfirmCompany from "./notRegisteredSteps/ConfirmCompany";

export default function CompanyRegister() {
  const match = useRouteMatch();

  return (
    <Layout>
      <Switch>
        <Route exact path={`${match.path}`}>
          <Question />
        </Route>

        <Route path={`${match.path}/account-info-not-registered`}>
          <AccountInfo notRegistered />
        </Route>
        <Route path={`${match.path}/details`}>
          <Details />
        </Route>
        <Route path={`${match.path}/finalize`}>
          <Finalize />
        </Route>
        <Route path={`${match.path}/company-code`}>
          <CompanyCode />
        </Route>

        <Route path={`${match.path}/account-info`}>
          <AccountInfo />
        </Route>
        <Route path={`${match.path}/enter-code`}>
          <EnterCode />
        </Route>
        <Route path={`${match.path}/confirm-company`}>
          <ConfirmCompany />
        </Route>
      </Switch>
    </Layout>
  );
}
