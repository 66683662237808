export const environment = {
  production: false,
  // apiUrl:
  //   "https://cors-anywhere.herokuapp.com/https://clearcontractsdev.uc.r.appspot.com/api/v1",
  // socketUrl: "http://localhost:8080",
  // apiUrl: "http://localhost:8080/api/v1",
  socketUrl: "http://clearcontractsdev.uc.r.appspot.com",
  apiUrl: "https://clearcontractsdev.uc.r.appspot.com/api/v1",

  FORMATIC_KEY: "pk_test_DB7280FC0500639A",
  KRACKEN_KEY: "eZ7zl0g3geN22ORehAg2RNxFCXf6/COHvpYCjlZyg9VsBB9dHwkd7wu3",
  KRACKEN_SECRET:
    "HCvtiR/tGfP6FQP5FsN+7fvwfeDF9gLZbH7E72tYBE33wnuVZMyHjUUo34Eh7iWQhk7eHj21wPa1FqNPwPhtMg==",
  WYM_ETH_ADDRESS: "0xB9D2b8D11b98d8BD099b3250afcA74F9A19DA11e",
};
