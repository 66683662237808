import React from "react";
import {
  styled,
  makeStyles,
  Typography as MuiTypography,
  Button,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import StepCard from "../../../../layout/Step/StepCard";

const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles((theme) => ({
  code: {
    backgroundColor: theme.palette.secondaryBackground,
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    borderRadius: 4,
    padding: 8,
    width: "100%",
    "@media (max-width:600px)": {
      width: "calc(100% - 16px)",
    },
  },
  button: {
    marginLeft: "auto",
    marginTop: 60,
    "@media (max-width:600px)": {
      marginLeft: "initial",
    },
  },
}));

export default function CompanyCode() {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const history = useHistory();

  const { companyName, accessCode } = useSelector(({ company }) => company);

  return (
    <StepCard title="You’re All Set!">
      <Grid container direction="column" alignItems="center">
        <Typography mt={-6} align="center">
          Time to invite your team. To invite new members to access your company
          page on Clear Contracts, please share this code.
        </Typography>

        <Typography variant="h4" align="center" mt={4}>
          {companyName}’s Access Code
        </Typography>

        <Typography className={classes.code} variant="h4" align="center" mt={2}>
          {accessCode}
        </Typography>

        <Button
          type="submit"
          variant="contained"
          color="secondary"
          className={classes.button}
          fullWidth={isMobile}
          onClick={() => history.push("/dashboard")}
        >
          Enter Clear Contracts©
        </Button>
      </Grid>
    </StepCard>
  );
}
