import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Layout from "../../../layout/Step/Step";
import AccountData from "./steps/AccountData";
import GeneralData from "./steps/GeneralData";
import DetailsData from "./steps/DetailsData";

export default function InfluencerRegister() {
  const match = useRouteMatch();

  return (
    <Layout>
      <Switch>
        <Route exact path={`${match.path}`}>
          <AccountData />
        </Route>
        <Route path={`${match.path}/general-data`}>
          <GeneralData />
        </Route>
        <Route path={`${match.path}/details-data`}>
          <DetailsData />
        </Route>
      </Switch>
    </Layout>
  );
}
