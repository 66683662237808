import React from "react";
import { withStyles } from "@material-ui/core";
import { TextField } from "@material-ui/core";

const StyledTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.label.main,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.secondaryBackground,
      "& fieldset": {
        borderColor: theme.palette.label.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.label.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.label.main,
      },
    },
  },
}))(TextField);

export default function FormikTextField(props) {
  return <StyledTextField {...props} />;
}
