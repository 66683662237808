import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import authSlice from "./slices/authSlice";
import companySlice from "./slices/companySlice";
import memberSlice from "./slices/memberSlice";
import influencerSlice from "./slices/influencerSlice";
import influencersSlice from "./slices/influencers";
import conversationSlice from "./slices/conversation";
import dashboardSlice from "./slices/dashboardSlice";
import contractSlice from "./slices/contractSlice";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  auth: authSlice,
  company: companySlice,
  member: memberSlice,
  influencer: influencerSlice,
  influencers: influencersSlice,
  conversation: conversationSlice,
  dashboard: dashboardSlice,
  contract: contractSlice
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export default store;
