import { React, useRef, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";


import Layout from "../../layout/Internal";
import InfluencerProfileWidget from "./InfluencerProfileWidget";
import CompanySpecialProfileWidget from "./CompanySpecialProfileWidget";
import SocialsWidget from "./SocialsWidget";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    padding: "80px 0 0 80px",
    "@media (max-width: 600px)": { padding: 0 },
  },
});
var abc = "";
export default function CompanyProfile({ member }) {
  const classes = useStyles();

  const { id } = useParams();

  const userType = useSelector(({ auth }) => auth.user.type);

  return (
    <Layout>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <CompanySpecialProfileWidget id={id}/>
        </Grid>
        {/* <Grid item xs={12}>
          <SocialsWidget member={member}/>
        </Grid> */}
      </Grid>


    </Layout>
  );
}
