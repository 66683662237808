import React from "react";
import {
  makeStyles,
  styled,
  Button,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Autocomplete } from "formik-material-ui-lab";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import StepCard from "../../../../layout/Step/StepCard";
import FormikTextField from "../../../../../components/layout/forms/FormikTextField";
import BaseAutocompleteTextField from "../../../../../components/layout/forms/AutocompleteTextField";
import industryOptions from "../../../../../data/industryOptions.json";
import { update } from "../../../../../redux/slices/companySlice";

const TextField = styled(FormikTextField)(spacing);
const AutocompleteTextField = styled(BaseAutocompleteTextField)(spacing);

const useStyles = makeStyles(() => ({
  button: { marginLeft: "auto", marginTop: 60 },
}));

export default function AccountData() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const {
    id: companyId,
    companyName,
    industry,
    website,
  } = useSelector(({ company }) => company);

  return (
    <Formik
      initialValues={{
        companyName: companyName || "",
        industry: industry || null,
        website: website || "",
      }}
      validationSchema={Yup.object({
        companyName: Yup.string().max(255).required("Required"),
        industry: Yup.string().nullable().required("Required"),
        website: Yup.string()
          .nullable()
          .matches(
            /^$|((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            "It's not a valid website "
          ),
      })}
      onSubmit={async (
        { companyName, industry, website },
        { setErrors, setStatus, setSubmitting }
      ) => {
        try {
          const response = await dispatch(
            update({
              id: companyId,
              body: {
                company_name: companyName,
                industry,
                website_url: website,
              },
            })
          );

          if (response.type && response.type === "company/update/fulfilled") {
            history.push("finalize");
          }
        } catch (err) {
          setSubmitting(false);
          setStatus({ success: false });
          setErrors({ submit: "An error ocurred" });
        }
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <StepCard title="Company Details" loading={isSubmitting}>
            <Grid container>
              <Field
                component={TextField}
                name="companyName"
                type="text"
                label="Company Name"
                fullWidth
                variant="outlined"
                mb={3}
              />

              <Field
                name="industry"
                component={(params) => <Autocomplete {...params} />}
                options={industryOptions}
                getOptionLabel={(option) => option}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <AutocompleteTextField
                    {...params}
                    error={touched.industry && !!errors.industry}
                    helperText={errors.industry}
                    label="Industry"
                    variant="outlined"
                    mb={3}
                  />
                )}
              />

              <Field
                component={TextField}
                name="website"
                type="text"
                label="Company Website URL"
                fullWidth
                variant="outlined"
                mb={3}
              />

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
                fullWidth={isMobile}
              >
                Next
              </Button>
            </Grid>

            {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}
          </StepCard>
        </Form>
      )}
    </Formik>
  );
}
