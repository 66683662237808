import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  styled,
  Button,
  Grid as MuiGrid,
  Typography as MuiTypography,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  useMediaQuery
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { getCall, postCall } from "../../../utils/apicalls";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { environment } from "../../../environments/environment";

import ResponsiveCard from "../../layout/papers/ResponsiveCard";
import { Launcher } from "react-chat-window";
import Layout from "../../layout/Internal";
import io from "socket.io-client";

import { getConversation } from "../../../redux/slices/conversation";
import { saveMessage } from "../../../redux/slices/messageSlice";
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles((theme) => ({
  container: { maxWidth: 980 },
  item: { backgroundColor: theme.palette.secondaryBackground, marginBottom: 6 },
  itemText: { paddingRight: 40 },
  width: { width: 80 }
}));
var abc = "";
export default function Members() {
  const classes = useStyles();
  const history = useHistory();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const userAuthId = useSelector(({ auth }) => auth.user.id);
  const [contractList, setContractList] = useState([]);
  const [messagesList, setMessagesList] = useState([]);
  const [connectionList, setConnectionList] = useState([]);
  const { type } = useSelector(({ auth }) => auth.user);


  const getMessagesList = async () => {
    setMessagesList([]);

    const response = await postCall("/getConversations", {
      id: userAuthId,
      user_type: type,
    });
    console.log(response.conversations);
    console.log(response.conversations.length);
    setMessagesList(response.conversations);
  };

  const getContractList = async () => {
    const response = await getCall(`/getContracts/${userAuthId}`);
    setContractList(response?.data);
  };

  const getConnectionList = async () => {
    const response = await getCall(`/getNotifications/${userAuthId}`);
    setConnectionList(response?.data);
  };

  const [conversationName, setConversationName] = useState('');
  const [conversationImage, setConversationImage] = useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userId, setUserId] = React.useState(null);
  const [companyId, setCompanyId] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [messages, setMessages] = useState([]);
  const [showLauncher, setShowLauncher] = useState(false);
  const [launcherOpen, setlauncherOpen] = useState(true);
  const [yourID, setYourID] = useState();
  const userType = useSelector(({ auth }) => auth.user.type);
  const dispatch = useDispatch();

  const conversation = useSelector(({ conversation }) => conversation);

  const user = useSelector(({ auth }) => auth.user);
  const socketRef = useRef();
  useEffect(() => {
    socketRef.current = io.connect(environment.socketUrl);

    socketRef.current.on("your id", id => {
      abc = id;
      setYourID(id);
    });

    socketRef.current.on("message", (message) => {
      _sendMessage(message);
    });
  }, []);
  useEffect(() => {
    if (conversation.conversation.conversation) {
      setMessages([]);
      let msgs = conversation.conversation.conversation.ConversationReplies;
      console.log(conversation.conversation.conversation)
      if(type=="company"){
        setConversationName(conversation.conversation.conversation.User.name)
        setConversationImage(conversation.conversation.conversation.User.image)
      }else{
        setConversationName(conversation.conversation.conversation.Company.company_name)
        setConversationImage(conversation.conversation.conversation.Company.image)
      }
      msgs.map((message) => {
        let obj = {
          text: message.reply,
          // id: yourID,
        };
        let author = "";
        if (userType === "company") {
          if (message.company_id) {
            author = "me";
          } else {
            author = "them";
          }
        } else {
          if (message.user_id) {
            author = "me";
          } else {
            author = "them";
          }
        }
        const messageObject2 = {
          author: author,
          type: "text",
          data: obj,
        };
        setMessages(oldMsgs => [...oldMsgs, messageObject2]);
      })
    }
  }, [conversation]);
  function _onMessageWasSent(message) {
    let obj = {
      text: message.data.text,
      id: yourID,
      company_id: companyId,
      user_id: userId,
    };
    const messageObject2 = {
      author: "me",
      type: "text",
      data: obj,
    };
    socketRef.current.emit("send message", messageObject2);
    const body = {
      data: {
        company_id: companyId,
        user_id: userId,
        sender: userType,
        reply: message.data.text,
      },
    };
    dispatch(saveMessage(body));


  }

  function _sendMessage(text) {
    if (userType === "influencer") {
      if (user.id == text.data.user_id) {
        setShowLauncher(true);
        setUserId(text.data.user_id);
        setCompanyId(text.data.company_id);
        let object = {
          company_id: text.data.company_id,
          user_id: text.data.user_id,
        };
        dispatch(getConversation(object));
        if (text.data.id === abc) {
          const messageObject2 = {
            author: "me",
            type: "text",
            data: text.data,
          };
          setMessages(oldMsgs => [...oldMsgs, messageObject2]);

        } else {
          const messageObject2 = {
            author: "them",
            type: "text",
            data: text.data,
          };
          setMessages(oldMsgs => [...oldMsgs, messageObject2]);

        }
      }
    } else {
      if (user.id == text.data.company_id) {
        setShowLauncher(true);
        setUserId(text.data.user_id);
        setCompanyId(text.data.company_id);
        let object = {
          company_id: text.data.company_id,
          user_id: text.data.user_id,
        };
        dispatch(getConversation(object));
        if (text.data.id === abc) {
          const messageObject2 = {
            author: "me",
            type: "text",
            data: text.data,
          };
          setMessages(oldMsgs => [...oldMsgs, messageObject2]);

        } else {
          const messageObject2 = {
            author: "them",
            type: "text",
            data: text.data,
          };
          setMessages(oldMsgs => [...oldMsgs, messageObject2]);

        }
      }
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const listClick = (company_id, user_id,user,company) => {
    setAnchorEl(null);
    setShowLauncher(true);
    setUserId(user_id);
    setCompanyId(company_id);

    if(type=="company"){
      setConversationName(user.name)
      setConversationImage(user.image)
    }else{
      setConversationName(company.company_name)
      setConversationImage(company.image)
    }

    let object = {
      company_id,
      user_id,
    };
    dispatch(getConversation(object));
  };
  const launcherClick = (e) => {
    setShowLauncher(false);

  };


  useEffect(() => {
    getContractList();
    getMessagesList();
    getConnectionList();
  }, []);

  return (
    <Layout >
      <Typography variant="h3">Notifications</Typography>

      <Grid style={isMobile ? {} : { 'marginLeft': '110px' }} container spacing={2} className={classes.container} mt={4}>
        <Grid item xs={12} md={4}>
          <Card>
            <Typography variant="h6" align="center" mt={3} mb={1}>
              Messages
            </Typography>

            <List>
              {/* {messagesList.length == 0 && (
                 <ListItem>
                    <ListItemText>
                    No Message
                  </ListItemText>
                 </ListItem>
              )} */}

        {
          messagesList.length != 0 &&
          messagesList.map((item, i) => (
            <List style={{ "cursor": "pointer" }} key={i} dense >
              <ListItem className={classes.item}>
                <ListItemAvatar>
                  {
                    type != "company" ?
                      item.Company.image ?
                        <Avatar alt={item.Company.company_name} src={item.Company.image}/> :
                        <Avatar>{item.Company.company_name}</Avatar> : item.User.image ?
                      <Avatar alt={item.User.name} src={item.User.image}/> :
                      <Avatar>{item.User.name}</Avatar>
                  }

                </ListItemAvatar>

                <ListItemText primary={type != "company" ? item.Company.company_name : item.User.name}/>
                <ListItemSecondaryAction>
                    <Button variant="contained" color="primary" size="small" onClick={()=>listClick(item.company_id, item.user_id,item.User,item.Company)}>
                      Chat
                    </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          ))}


            </List>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <Typography variant="h6" align="center" mt={3} mb={1}>
              Contracts
            </Typography>

            <List>
              {!contractList.length && (
                <ListItem>
                  <ListItemText>
                    No Contract
                  </ListItemText>
                </ListItem>
              )}
              {contractList.map((item, i) => (
                <ListItem className={classes.item} key={i}>
                  <ListItemAvatar>
                    <Avatar />
                  </ListItemAvatar>

                  <ListItemText className={classes.itemText}>
                    {item.description}
                  </ListItemText>

                  <ListItemSecondaryAction>
                    <Button variant="contained" color="primary" size="small" onClick={() => history.push(`/contract/${item.id}`)}>
                      view
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <Typography variant="h6" align="center" mt={3} mb={1}>
              Connections
            </Typography>
            <List>
              {!connectionList.length && (
                <ListItem>
                  <ListItemText>
                    No Connection
                  </ListItemText>
                </ListItem>
              )}

              {connectionList.map((item, i) => {
                if (item.type == 2 ) {
                  return (<ListItem className={classes.item} key={i}>
                    <ListItemAvatar>
                      <Avatar />
                    </ListItemAvatar>
  
                    <ListItemText className={classes.itemText}>
                      <div dangerouslySetInnerHTML={{ __html: item.text }} />
                    </ListItemText>
  
                    <ListItemSecondaryAction>
                      <Button variant="contained" color="primary" size="small"
                        onClick={() => history.push(`/member-profile/${item.PersonId}`)}
                      >
                        view
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>);
                }
              })}
            </List>
          </Card>
        </Grid>
      </Grid>
      {
        showLauncher &&
        <Launcher
          agentProfile={{
            teamName: conversationName,
            // imageUrl: <Avatar src={conversationImage}></Avatar>,
          }}
          onMessageWasSent={_onMessageWasSent}
          messageList={messages}
          isOpen={launcherOpen}
          handleClick={launcherClick}
          showEmoji
        />
      }
    </Layout>
  );
}
