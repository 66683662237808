import React from "react";
import { Container } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import LockIcon from "@material-ui/icons/Lock";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import FastForwardIcon from "@material-ui/icons/FastForward";
import DnsIcon from "@material-ui/icons/Dns";

import LandingAuth from "./sections/LandingAuth";
import LandingHero from "./sections/LandingHero";
import LandingMission from "./sections/LandingMission";
import LandingFeatures from "./sections/LandingFeatures";

export default function LandingCompany(props) {
  if (props.isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  const features = [
    {
      Icon: HourglassEmptyIcon,
      title: "Get paid out quickly",
      description: `No more waiting weeks to get paid after posting for a company.  
      Get paid quickly for endorsement deals you make on our platform!`,
    },
    {
      Icon: LockIcon,
      title: "Make deals from anywhere",
      description: `Enter endorsement agreements with companies easily from your phone. 
      View agreement information at anytime to see deadlines, payment 
     amounts, and more.`,
    },
    {
      Icon: CompareArrowsIcon,
      title: "Connect with Companies",
      description: `Easily find and connect with your favorite brands, and express interest in brands you 
      want to work with.
      `,
    },
    {
      Icon: FastForwardIcon,
      title: "Negotiate Easily",
      description: `Negotiate your contracts to make sure you’re getting paid what you’re
      worth!`,
    },
    {
      Icon: DnsIcon,
      title: "Efficient Organization",
      description: `We organize your contracts all in one place in a way that will never confuse you.`,
    },
  ];

  return (
    <>
      <LandingAuth />
      <Container maxWidth="md">
        <LandingHero text="Connecting Influencers and Brands like never before." />
        <LandingMission />
        <LandingFeatures features={features} />
      </Container>
    </>
  );
}
