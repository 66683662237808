import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCall } from "../../utils/apicalls";

const initialState = {
    status: "idle",
    error: "",
    users: [],
    interests: [],
    contracts: [],
};

export const getLatest = createAsyncThunk(
    "dashboard/getLatest",
    async (id) => {
        const response = await getCall(`/getLatest`);
        return response;
    }
);

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getLatest.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.users = action.payload.users;
                // state.interests = action.payload.interests;
                // state.contracts = action.payload.contracts;
            })
            .addCase(getLatest.pending, (state, action) => {
                state.status = "loading";
                state.error = "";
            })
    },
});

export default dashboardSlice.reducer;
