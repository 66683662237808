import React from "react";
import {
  makeStyles,
  styled,
  Button,
  Grid as MuiGrid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import StepCard from "../../../../layout/Step/StepCard";
import FormikTextField from "../../../../../components/layout/forms/FormikTextField";
import { update } from "../../../../../redux/slices/companySlice";
import { postCallWithFile } from "../../../../../utils/apicalls";

const TextField = styled(FormikTextField)(spacing);
const Grid = styled(MuiGrid)(spacing);
const UploadInput = styled("input")({ display: "none" });
const UploadIcon = styled(CloudUploadIcon)({ color: "white" });

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: "auto",
    marginTop: 20,
  },
}));

export default function Finalize() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { id: companyId, bio } = useSelector(({ company }) => company);

  return (
    <Formik
      initialValues={{ image: "", bio: bio || "" }}
      validationSchema={Yup.object({
        image: Yup.mixed().test(
          "allowed-size",
          "Maximun size: 5MB",
          (value) => {
            if (value) {
              return value.size <= 5242880;
            } else {
              return true;
            }
          }
        ),
        bio: Yup.string().max(600, "Must be less than 600 characters"),
      })}
      onSubmit={async (
        { image, bio },
        { setErrors, setStatus, setSubmitting }
      ) => {
        try {
          const response = await dispatch(
            update({
              id: companyId,
              body: {
                bio,
              },
            })
          );

          if (image) {
            let formData = new FormData();
            formData.append("myFile", image);
            formData.append('company_id', companyId);
            await postCallWithFile("/saveCompanyImage", formData);
          }

          if (response.type && response.type === "company/update/fulfilled") {
            history.push("company-code");
          }
        } catch (err) {
          setSubmitting(false);
          setStatus({ success: false });
          setErrors({ submit: "An error ocurred" });
        }
      }}
    >
      {({ isSubmitting, errors, touched, values, setValues }) => (
        <Form>
          <StepCard title="Finalize Your Company Page" loading={isSubmitting}>
            <Grid container>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                mb={4}
              >
                <UploadInput
                  accept="image/png, image/jpeg"
                  id="upload-button-file"
                  type="file"
                  onChange={(e) => {
                    setValues({ ...values, image: e.target.files[0] });
                  }}
                />
                <label htmlFor="upload-button-file">
                  <Button
                    variant="contained"
                    color="secondary"
                    component="span"
                    startIcon={<UploadIcon />}
                  >
                    Upload Logo
                  </Button>
                </label>

                {values.image && <Typography>{values.image.name}</Typography>}
                {errors.image && (
                  <Typography color="error">{errors.image}</Typography>
                )}
              </Grid>

              <Field
                component={TextField}
                name="bio"
                type="text"
                label="Company Bio..."
                fullWidth
                variant="outlined"
                multiline
                rows={5}
                mb={3}
              />

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
                fullWidth={isMobile}
              >
                Submit
              </Button>
            </Grid>

            {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}
          </StepCard>
        </Form>
      )}
    </Formik>
  );
}
