import React from "react";
import "./App.css";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";

import Landing from "./pages/landing/Landing.js";
import LandingInfluencer from "./pages/landing/LandingInfluencer.js";
import LandingCompany from "./pages/landing/LandingCompany.js";
import Register from "./pages/register/Register";
import InfluencerRegister from "./pages/register/influencer/InfluencerRegister";
import CompanyLoginQuestion from "./pages/login/CompanyLoginQuestion";
import Login from "./pages/login/Login";
import ForgotPassword from "./pages/ForgotPassword";
import CompanyRegister from "./pages/register/company/CompanyRegister";
import Dashboard from "./pages/dashboard/Dashboard";
import Profile from "./pages/profile/Profile";
import CompanyProfile from "./pages/profile/CompanyProfile";

import EditProfile from "./pages/EditProfile/EditProfile";
import NewContract from "./pages/NewContract/NewContract";
import Contract from "./pages/Contracts/Contract";
import ViewContracts from "./pages/Contracts/ViewContracts";
import Members from "./pages/Members/Members";
import Notifications from "./pages/Notifications/Notifications";

export default function Routes() {
  const { isAuthenticated } = useSelector(({ auth }) => auth);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/influencer" component={LandingInfluencer} />
        <Route exact path="/company" component={LandingCompany} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/company-login" component={Login}>
          <CompanyLoginQuestion />
        </Route>
        <Route exact path="/company-owner-login">
          <Login company />
        </Route>
        <Route exact path="/company-member-login">
          <Login member />
        </Route>
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/register" component={Register} />
        <Route path="/influencer-register" component={InfluencerRegister} />
        <Route path="/company-register" component={CompanyRegister} />

        {isAuthenticated ? (
          <>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/profile" component={Profile} />
            <Route path="/influencer-edit-profile" component={EditProfile} />

            <Route exact path="/member-profile/:id">

              <Profile member />
            </Route>
            <Route exact path="/company-profile/:id">
              <CompanyProfile member />
            </Route>
            <Route path="/new-contract" component={NewContract} />
            <Route path="/contract/:status" component={Contract} />
            <Route exact path="/view-contracts" component={ViewContracts} />
            <Route exact path="/members" component={Members} />
            <Route exact path="/notifications" component={Notifications} />
          </>
        ) : (
          <Redirect to="/login" />
        )}
      </Switch>
    </Router>
  );
}
