import React, { useEffect } from "react";
import {
  Hidden,
  useMediaQuery,
  makeStyles,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import defaultImage from "../../../../../assets/images/default-profile-picture.png";
import { getProfile } from "../../../../../redux/slices/companySlice";

const useStyles = makeStyles(() => ({
  imageContainer: {
    "@media (max-width: 600px)": { order: -1 },
  },
  image: { height: 260, width: 220, maxWidth: "100%"},
  buttonsContainer: { flexGrow: 1, paddingTop: 28 },
}));

export default function CompanyProfileInfo() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { companyName, industry, bio, image, id, accessCode } = useSelector(
    ({ company }) => company
  );

  useEffect(() => {
    dispatch(getProfile(id));
  }, [dispatch, id]);

  return (
    <Grid container>
      <Grid container direction="column" item md={7}>
        <Hidden smDown>
          <Typography variant="h6">{companyName}</Typography>
          <Typography variant="h6" gutterBottom>
            {industry}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Access Code: {accessCode}
          </Typography>
          <Typography gutterBottom>{bio}</Typography>
        </Hidden>

        <Grid
          container
          spacing={2}
          alignItems="flex-end"
          justify={isMobile ? "center" : "flex-start"}
          className={classes.buttonsContainer}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size={isMobile ? "small" : "medium"}
              onClick={() => history.push("/profile")}
            >
              View Profile
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              size={isMobile ? "small" : "medium"}
              onClick={() => history.push("/company-register/details")}
            >
              Edit profile
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justify={isMobile ? "center" : "flex-end"}
        item
        md={5}
        className={classes.imageContainer}
      >
        <img src={image ? image!=='/assets/images/default.png'?image:defaultImage : defaultImage} alt="" className={classes.image} />
      </Grid>
    </Grid>
  );
}
