import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  makeStyles,
  useMediaQuery,
  Hidden,
  Grid,
  Typography,
  Button,
  Divider,
  Chip,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import defaultImage from "../../../assets/images/default-profile-picture.png";
import ResponsiveCard from "../../layout/papers/ResponsiveCard";
import {
  getProfile,
  getInstagramData, update,
} from "../../../redux/slices/influencerSlice";
import { getLocation } from "../../../utils/common";
import { getCall, postCall } from "../../../utils/apicalls";
import io from "socket.io-client";
import { Launcher } from "react-chat-window";
import { useParams } from "react-router-dom";
import { getConversation } from "../../../redux/slices/conversation";
import { saveMessage } from "../../../redux/slices/messageSlice";
import Layout from "../../layout/Internal";
import { environment } from "../../../environments/environment";
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  container: { position: "relative" },
  image: {
    position: "absolute",
    top: -40,
    left: -40,
    height: 260,
    width: 260,
    objectFit: "cover",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "@media (max-width: 600px)": {
      position: "relative",
      top: "initial",
      left: "initial",
    },
  },
  card: { padding: 24 },
  leftColumn: { paddingTop: 220 },
  nameContainer: {
    marginBottom: 16,
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 600px)": { margin: "16px 0" },
  },
  button: { minWidth: 160 },
  dialogButton: { margin: 4, marginBottom: 12 },
  chip: { minWidth: 100 },
  loading: {
    width: '100%',
    '& > * + *': {
      marginTop: 30,
    },
  }
});
var abc = "";
export default function InfluencerProfileWidget({ member, memberId }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const userId = useSelector(({ auth }) => auth.user.id);
  // const influencerId = useSelector(({ auth }) => auth.user.id);

  const {
    id:influencerId,
    city,
    state,
    country,
    age,
    bio,
    interests,
    name,
    instagram,
    followers,
    following,
    image,
    status
  } = useSelector(({ influencer }) => influencer);

  const [expressedInterest, setExpressedInterest] = useState(false);
  const [messages, setMessages] = useState([]);
  const [yourID, setYourID] = useState();
  const [chatOpen, setChatOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const userType = useSelector(({ auth }) => auth.user.type);
  const user = useSelector(({ auth }) => auth.user);
  const conversation = useSelector(({ conversation }) => conversation);
  const socketRef = useRef();
  useEffect(() => {
    socketRef.current = io.connect(environment.socketUrl, { transport : ['websocket'] });
    socketRef.current.on("your id", id => {
      abc = id;
      setYourID(id);
    });

    socketRef.current.on("message", (message) => {
      _sendMessage(message);
    });
  }, []);
  useEffect(() => {
    setMessages([])
    if (conversation.conversation.conversation) {
      let msgs = conversation.conversation.conversation.ConversationReplies;
      msgs.map((message) => {
        let obj = {
          text: message.reply,
          // id: yourID,
        };
        let author = (userType === "company" && message.company_id) ? 'me' : 'them'
        const messageObject2 = {
          author: author,
          type: "text",
          data: obj,
        };
        setMessages(oldMsgs => [...oldMsgs, messageObject2]);
      })
    }
  }, [conversation])

  function _onMessageWasSent(message) {
    let obj = {
      text: message.data.text,
      id: yourID,
      company_id: userType == "company" ? user.id : memberId,
      user_id: userType != "company" ? user.id : memberId,
    };
    const messageObject2 = {
      author: "me",
      type: "text",
      data: obj,
    };
    socketRef.current.emit("send message", messageObject2);
    const body = {
      data: {
        company_id: userType == "company" ? user.id : memberId,
        user_id: userType != "company" ? user.id : memberId,
        sender: userType,
        reply: message.data.text
      },
    }
    dispatch(saveMessage(body))
  }
  function _sendMessage(text) {

    if (text.data.id === abc) {
      const messageObject2 = {
        author: "me",
        type: "text",
        data: text.data,
      };
      setMessages(oldMsgs => [...oldMsgs, messageObject2]);

    } else {
      const messageObject2 = {
        author: "them",
        type: "text",
        data: text.data,
      };
      setMessages(oldMsgs => [...oldMsgs, messageObject2]);

    }

  }

  useEffect(() => {
    //Get Expressed Interests
    const getExpressedInterests = async () => {
      const response = await getCall(`/getInterests/${userId}`);

      const alreadyExpressed = response?.data?.reduce((acc, cur) => {
        return acc ? acc : cur.UserId === memberId;
      }, false);

      setExpressedInterest(alreadyExpressed);
    };

    getExpressedInterests();

    dispatch(
      getInstagramData({
        user_id: influencerId,
        instagram_name: instagram,
      })
    );
  }, [memberId, userId, instagram, influencerId, dispatch]);

  useEffect(() => {
    dispatch(getProfile(member ? memberId : influencerId));
  }, [dispatch, influencerId, memberId, member]);

  const handleEditClick = () => {
    history.push("/influencer-edit-profile/general-data");
  };

  const handleOfferClick = () => {
    history.push({pathname: "/new-contract", state: {
      type: 'new', influencerId: influencerId, contractId: ''
    }});
  };

  const IamgeWrapper = isMobile
    ? (props) => <Grid {...props} container justify="center" />
    : Fragment;

  //Dialog
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };
  const chatOpenFunc = () => {
    setMessages([])
    setChatOpen(true)
    setIsOpen(true)
    let object = {
      company_id: userType == "company" ? user.id : memberId,
      user_id: userType != "company" ? user.id : memberId,
    };
    dispatch(getConversation(object));

  };
  const handleExpressInterest = async () => {
    await postCall("/saveCreateInterest", {
      UserId: memberId,
      SenderId: userId,
      data: { status: "unread", senderName: user.name, userName: name },
    });

    setExpressedInterest(true);
    toggleOpen();
  };
  //End - Dialog

  return (
    <>
      {status == "loading" &&
        <div className={classes.loading}>
          <LinearProgress />
        </div>
      }

      {status != "loading" &&
        <div className={classes.container}>
          <IamgeWrapper>
            <img src={image ? image!=='/assets/images/default.png'?image:defaultImage : defaultImage} alt="" className={classes.image} />
          </IamgeWrapper>
          <ResponsiveCard>
            <Grid container>
              <Hidden smDown>
                <Grid item sm={3} className={classes.leftColumn}>
                  <Typography variant="h6">
                    {getLocation(city, state, country)}
                  </Typography>
                  <Typography variant="h6">{age} Years Old</Typography>
                </Grid>
              </Hidden>

              <Grid container item xs={12} sm={9} spacing={2}>
                <Grid
                  container
                  alignItems="center"
                  item
                  className={classes.nameContainer}
                >
                  <Grid item xs={12} sm={9}>
                    <Typography
                      variant={isMobile ? "h3" : "h1"}
                      align={isMobile ? "center" : "left"}
                    >
                      {name}
                    </Typography>
                  </Grid>
                  {member ? (
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      container
                      justify={isMobile ? "center" : "flex-end"}
                      spacing={1}
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          className={classes.button}
                          onClick={toggleOpen}
                          disabled={!!expressedInterest}
                        >
                          {expressedInterest
                            ? "Interest Expressed"
                            : "Express INTEREST"}
                        </Button>
                      </Grid>
                      {userType !== 'influencer' && <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOfferClick}
                          size="small"
                          className={classes.button}
                        >
                          OFFER CONTRACT
                      </Button>
                      </Grid>}
                      
                      {userType !== 'influencer' &&
                      <Grid item>
                        <Button onClick={chatOpenFunc}
                          variant="contained"
                          size="small"
                          className={classes.button}
                        >
                          MESSAGE
                      </Button>
                      </Grid>
                      }
                    </Grid>
                  ) : (
                      <Button
                        variant="contained"
                        size="small"
                        className={classes.button}
                        onClick={handleEditClick}
                      >
                        Edit
                      </Button>
                  )}
                </Grid>

                {
                  (following != '' && followers != '' ) && <Grid container justify="center" item>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      item
                      xs={4}
                    >
                      <Typography variant={isMobile ? "h5" : "h3"}>
                        {following == '' ? 'No Data' : following}
                      </Typography>
                      <Typography variant={isMobile ? "subtitle1" : "h6"}>
                        Following
                    </Typography>
                    </Grid>

                    <Grid
                      container
                      direction="column"
                      alignContent="space-between"
                      alignItems="center"
                      item
                      xs={4}
                    >
                      <Divider orientation="vertical" />
                      <Typography variant={isMobile ? "h5" : "h3"}>
                        {followers == '' ? 'No Data' : followers}
                      </Typography>
                      <Typography variant={isMobile ? "subtitle1" : "h6"}>
                        Followers
                    </Typography>
                      <Divider orientation="vertical" />
                    </Grid>


                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      item
                      xs={4}
                    >
                      <Typography variant={isMobile ? "h5" : "h3"}>
                        1.45M
                    </Typography>
                      <Typography variant={isMobile ? "subtitle1" : "h6"}>
                        Likes
                    </Typography>
                    </Grid>



                    <InstagramIcon fontSize="large" />
                  </Grid>
                }
                

                <Grid container direction="column" item>
                  <Typography variant="h6">About</Typography>
                  <Typography>{bio}</Typography>
                </Grid>

                <Grid container item>
                  <Typography variant="h6">Interests</Typography>
                  <Grid container spacing={2}>
                    {interests?.map((item, i) => (
                      <Grid item key={i}>
                        <Chip
                          label={item}
                          color="primary"
                          className={classes.chip}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ResponsiveCard>
        </div>
      }
      <Dialog open={open} onClose={toggleOpen}>
        <DialogTitle>Are you sure?</DialogTitle>
        <Grid container justify="center" className={classes.buttonsContainer}>
          <Button
            onClick={handleExpressInterest}
            color="secondary"
            variant="contained"
            className={classes.dialogButton}
          >
            Yes
          </Button>

          <Button
            onClick={toggleOpen}
            color="secondary"
            variant="contained"
            className={classes.dialogButton}
          >
            No
          </Button>
        </Grid>
      </Dialog>
      {
        chatOpen &&
        <Launcher
          agentProfile={{
            teamName: name,
            imageUrl: "https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png",
          }}
          onMessageWasSent={_onMessageWasSent}
          messageList={messages}
          isOpen={isOpen}
          handleClick={() => setIsOpen(!isOpen)}
          showEmoji
        />
      }

    </>
  );
}
