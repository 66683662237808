import React from "react";
import {
  makeStyles,
  Grid,
  Card,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

import CardProgress from "../forms/CardProgress";

const useStyles = makeStyles((theme) => ({
  container: {
    "@media (max-width:600px)": {
      flexWrap: "nowrap",
      paddingTop: 40,
    },
  },
  title: { marginBottom: 24 },
  content: {
    position: "relative",
    padding: "80px 80px 24px",
    overflow: "unset",
    "@media (max-width: 600px)": {
      padding: "80px 28px 24px",
      backgroundColor: theme.palette.tertiaryBackground,
    },
    "@media (min-width: 600px)": { height: "100%" },
  },
}));

export default function StepCard({
  title,
  innerTitle,
  children,
  loading,
  cardClass,
}) {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const Wrapper = React.useMemo(
    () =>
      isMobile
        ? (props) => <div {...props} />
        : (props) => (
            <Card
              {...props}
              className={cardClass ? cardClass : props.className}
            />
          ),
    [isMobile, cardClass]
  );
  return (
    <Grid container direction="column" className={classes.container}>
      {title && (
        <Typography variant="h3" align="center" className={classes.title}>
          {title}
        </Typography>
      )}

      <Wrapper className={classes.content}>
        {innerTitle && (
          <Typography variant="h3" align="center" className={classes.title}>
            {innerTitle}
          </Typography>
        )}
        {children}
        {loading && <CardProgress color="secondary" />}
      </Wrapper>
    </Grid>
  );
}
