import React from "react";
import { styled, Button, Grid, useMediaQuery } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import FormikTextField from "../../layout/forms/FormikTextField";
import Layout from "../../layout/Step/Step";
import StepCard from "../../layout/Step/StepCard";

const TextField = styled(FormikTextField)(spacing);

export default function ForgotPassword() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Layout>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Required"),
        })}
        onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <StepCard title="Forgot Password?" loading={isSubmitting}>
              <Grid container justify="center">
                <Field
                  component={TextField}
                  name="email"
                  type="text"
                  label="Email Address"
                  fullWidth
                  variant="outlined"
                  mb={3}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  fullWidth={isMobile}
                >
                  Submit
                </Button>
              </Grid>

              {errors.submit && (
                <Alert severity="warning">{errors.submit}</Alert>
              )}
            </StepCard>
          </Form>
        )}
      </Formik>
    </Layout>
  );
}
