import { environment } from "../environments/environment";
import store from "../redux/store";

export const getCall = async (url, signal) => {
  try {
    const {
      auth: { token },
    } = store.getState();

    const requestOptions = {
      method: "GET",
      signal: signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(environment.apiUrl + url, requestOptions);
    return await response.json();
  } catch (err) {
    if (signal) {
      if (!signal.aborted) {
        return { error: err };
      }
    } else {
      return { error: err };
    }
  }
};

export const postCall = async (url, data, signal) => {
  try {
    const {
      auth: { token },
    } = store.getState();

    const requestOptions = {
      method: "POST",
      signal: signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(environment.apiUrl + url, requestOptions);

    return await response.json();
  } catch (err) {
    if (signal) {
      if (!signal.aborted) {
        return { error: err };
      }
    } else {
      return { error: err };
    }
  }
};

export const postCallWithFile = async (url, data, signal) => {
  try {
    const {
      auth: { token },
    } = store.getState();
    const requestOptions = {
      method: "POST",
      signal: signal,
      headers: {
        Accept: "multipart/form-data",
        credentials: "include",
        Authorization: "Bearer " + token,
      },
      body: data,
    };

    const response = await fetch(environment.apiUrl + url, requestOptions);

    return await response.json();
  } catch (err) {
    if (signal) {
      if (!signal.aborted) {
        return { error: err };
      }
    } else {
      return { error: err };
    }
  }
};

export const putCall = async (url, data, signal) => {
  try {
    const {
      auth: { token },
    } = store.getState();

    const requestOptions = {
      method: "PUT",
      signal: signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(environment.apiUrl + url, requestOptions);

    return await response.json();
  } catch (err) {
    if (signal) {
      if (!signal.aborted) {
        return { error: err };
      }
    } else {
      return { error: err };
    }
  }
};

export const deleteCall = async (url, signal) => {
  try {
    const {
      auth: { token },
    } = store.getState();

    const requestOptions = {
      method: "DELETE",
      signal: signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(environment.apiUrl + url, requestOptions);
    return await response.json();
  } catch (err) {
    if (signal) {
      if (!signal.aborted) {
        return { error: err };
      }
    } else {
      return { error: err };
    }
  }
};
