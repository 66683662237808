import React, { Fragment, useEffect } from "react";
import {
  makeStyles,
  useMediaQuery,
  Hidden,
  Grid,
  Typography,
  Button,
  Divider,
  Chip,
  Link,
} from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import defaultImage from "../../../assets/images/default-profile-picture.png";
import ResponsiveCard from "../../layout/papers/ResponsiveCard";
import { getProfile } from "../../../redux/slices/companySlice";

const useStyles = makeStyles({
  container: { position: "relative" },
  image: {
    position: "absolute",
    top: -40,
    left: -40,
    height: 260,
    width: 260,
    objectFit: "cover",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "@media (max-width: 600px)": {
      position: "relative",
      top: "initial",
      left: "initial",
    },
  },
  card: { padding: 24 },
  leftColumn: { paddingTop: 220 },
  nameContainer: {
    marginBottom: 16,
    "@media (max-width: 600px)": { margin: "16px 0" },
  },
  button: { minWidth: 144 },
  chip: { minWidth: 100 },
});

export default function CompanyProfileWidget({ member }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { companyName, industry, website, bio, id, image } = useSelector(
    ({ company }) => company
  );

  useEffect(() => {
    dispatch(getProfile(id));
  }, [dispatch, id]);

  const handleEditClick = () => {
    history.push("/company-register/details");
  };

  const handleOfferClick = () => {
    history.push("/new-contract");
  };

  const IamgeWrapper = isMobile
    ? (props) => <Grid {...props} container justify="center" />
    : Fragment;

  return (
    <div className={classes.container}>
      <IamgeWrapper>
      <img src={image ? image!=='/assets/images/default.png'?image:defaultImage : defaultImage} alt="" className={classes.image} />
      </IamgeWrapper>
      <ResponsiveCard>
        <Grid container>
          <Hidden smDown>
            <Grid item sm={3} className={classes.leftColumn}>
              <Link href={`https://${website}`} target="_blank">
                <Typography variant="h6">{website}</Typography>
              </Link>
            </Grid>
          </Hidden>

          <Grid container item xs={12} sm={9} spacing={2}>
            <Grid
              container
              alignItems="center"
              item
              className={classes.nameContainer}
            >
              <Grid item xs={12} sm={9}>
                <Typography
                  variant={isMobile ? "h3" : "h1"}
                  align={isMobile ? "center" : "left"}
                >
                  {companyName}
                </Typography>
              </Grid>
              {member ? (
                <Grid
                  item
                  xs={12}
                  sm={3}
                  container
                  justify={isMobile ? "center" : "flex-end"}
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.button}
                    >
                      Express INTEREST
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOfferClick}
                      size="small"
                      className={classes.button}
                    >
                      OFFER CONTRACT
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.button}
                    >
                      MESSAGE
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  className={classes.button}
                  onClick={handleEditClick}
                >
                  Edit
                </Button>
              )}
            </Grid>
            <Grid container direction="column" item>
              <Typography variant="h6">About</Typography>
              <Typography>{bio}</Typography>
            </Grid>

            <Grid container item>
              <Typography variant="h6">Industry</Typography>
              <Grid container spacing={2}>
                {[industry].map((item, i) => (
                  <Grid item key={i}>
                    <Chip
                      label={item}
                      color="primary"
                      className={classes.chip}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ResponsiveCard>
    </div>
  );
}
