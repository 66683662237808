import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { postCall } from "../../utils/apicalls";

const initialState = {
  status: "idle",
  error: "",
  message: {},
};

export const saveMessage = createAsyncThunk(
  "message",
  async (body, { dispatch }) => {
    const response = await postCall("/saveCreateMessage", body);

    // dispatch(
    //   authenticate({
    //     token: response.data.token,
    //     userType: "company",
    //     userId: response.id,
    //     userName: response.user_name,
    //     email: response.email,
    //   })
    // );

    return response;
  }
);
const messageSlice = createSlice({
  name: "message",
  initialState,
  extraReducers: (builder) => {
    builder

      .addCase(saveMessage.pending, (state, action) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(saveMessage.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          const data = action.payload;
          // const result = Object.values(data);
          state.message = data;

        }
      });
  },
});

export default messageSlice.reducer;

