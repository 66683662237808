import React, { useState, useEffect } from "react";
import {
  makeStyles,
  styled,
  Button,
  Grid as MuiGrid,
  Typography,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import CloudUploadIcon  from "@material-ui/icons/CloudUpload";
import {Payment}  from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import StepCard from "../../layout/Step/StepCard";
import FormikTextField from "../../layout/forms/FormikTextField";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { save, update } from "../../../redux/slices/contractSlice";
import { Switch, Route, useRouteMatch, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { postCallWithFile } from "../../../utils/apicalls";
import { getContract } from "../../../redux/slices/contractSlice";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from "@material-ui/pickers";

const TextField = styled(FormikTextField)(spacing);
const Grid = styled(MuiGrid)(spacing);
const UploadInput = styled("input")({ display: "none" });
const UploadIcon = styled(CloudUploadIcon)({ color: "white" });

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: "auto",
    marginTop: 20,
  },
  uploadButton: { height: 56 },
}));
const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#87bbfd",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange}/>
  </div>
);

const FormField = ({
                 label,
                 id,
                 type,
                 placeholder,
                 required,
                 autoComplete,
                 value,
                 onChange,
               }) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const ResetButton = ({ onClick }) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <path
        fill="#FFF"
        d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
      />
    </svg>
  </button>
);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: billingDetails,
    });

    setProcessing(false);

    if (payload.error) {
      setError(payload.error);
    } else {
      setPaymentMethod(payload.paymentMethod);
    }
  };

  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setBillingDetails({
      email: "",
      phone: "",
      name: "",
    });
  };

  return paymentMethod ? (
    <div className="Result">
      <div className="ResultTitle" role="alert">
        Payment successful
      </div>
      <div className="ResultMessage">
        Thanks for trying Stripe Elements. No money was charged, but we
        generated a PaymentMethod: {paymentMethod.id}
      </div>
      <ResetButton onClick={reset}/>
    </div>
  ) : (
    <form className="Form" onSubmit={handleSubmit}>
      <fieldset className="FormGroup">
        <FormField
          label="Name"
          id="name"
          type="text"
          placeholder="Jane Doe"
          required
          autoComplete="name"
          value={billingDetails.name}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, name: e.target.value });
          }}
        />
        <FormField
          label="Email"
          id="email"
          type="email"
          placeholder="janedoe@gmail.com"
          required
          autoComplete="email"
          value={billingDetails.email}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, email: e.target.value });
          }}
        />
        <FormField
          label="Phone"
          id="phone"
          type="tel"
          placeholder="(941) 555-0123"
          required
          autoComplete="tel"
          value={billingDetails.phone}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, phone: e.target.value });
          }}
        />
      </fieldset>
      <fieldset className="FormGroup">
        <CardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
      </fieldset>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <SubmitButton processing={processing} error={error} disabled={!stripe}>
        Pay
      </SubmitButton>
    </form>
  );
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
export default function CreateContract(props) {
  const { type, influencerId, contractId } = (props.location && props.location.state) || {};
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const userId = useSelector(({ auth }) => auth.user.id);
  const [open, setOpen] = useState(false);
  const contractInfo = useSelector(({ contract }) => contract);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
    <Formik
      initialValues={{
        description: (type == 'edit' && contractInfo.hasOwnProperty('description'))? contractInfo.description : '',
        deadline: (type == 'edit' && contractInfo.hasOwnProperty('deadline'))? contractInfo.deadline : new Date(),
        image: (type == 'edit' && contractInfo.hasOwnProperty('image'))? contractInfo.image : '',
        amount: (type == 'edit' && contractInfo.hasOwnProperty('payment_amount'))? contractInfo.payment_amount : '',
        stripe: '',
      }}
      validationSchema={Yup.object({
        description: Yup.string().max(600, "Must be less than 600 characters").required("Required"),
        deadline: Yup.string().required("Required"),
        image: Yup.mixed().test(
          "allowed-size",
          "Maximun size: 5MB",
          (value) => {
            if (value) {
              return value.size <= 5242880;
            } else {
              return true;
            }
          },
        ),
        amount: Yup.string().required("Required"),
        stripe: Yup.string(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          let response = '';
          if (contractInfo.hasOwnProperty('description')) {
             response = await dispatch(
              update({
                player_id: influencerId,
                company_id: userId,
                data: {
                  description: values.description,
                  deadline: values.deadline,
                  payment_amount: values.amount,
                  stripe: "1234",
                  status: null,
                  player_confirm_completed: false,
                  company_confirm_completed: false
                }
              })
            );
          } else {
             response = await dispatch(
              save({
                player_id: influencerId,
                company_id: userId,
                data: {
                  description: values.description,
                  deadline: values.deadline,
                  payment_amount: values.amount,
                  stripe: "1234",
                  status: null,
                  player_confirm_completed: false,
                  company_confirm_completed: false
                }
              })
            );
          }
          

          if (values.image) {
            let formData = new FormData();
            formData.append("myFile", values.image);
            formData.append('contract_id', response.payload.id);
            await postCallWithFile("/saveContractImage", formData);
          }

          if (response.type) {
            history.push(`/new-contract/verify-contract?${influencerId}`);
          }

        } catch (err) {
          setSubmitting(false);
          setStatus({ success: false });
          setErrors({ submit: "An error ocurred" });
        }
      }}
    >
      {({ isSubmitting, errors, touched, values, setValues }) => (
        <Form>
          <StepCard loading={isSubmitting}>
            <Grid container>
              <Field
                component={TextField}
                name="description"
                type="text"
                label="Description"
                placeholder="Describe what you’d like this influencer to post..."
                fullWidth
                variant="outlined"
                multiline
                rows={5}
                mb={3}
              />

              <Grid item container mb={2} spacing={2}>
                <Grid item xs={6}>
                  {/* <Field
                    component={TextField}
                    name="deadline"
                    type="text"
                    label="Deadline(MM-DD-YYYY)"
                    fullWidth
                    variant="outlined"
                  /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils} variant="inline">
                    <KeyboardDatePicker
                      disableToolbar
                      fullWidth
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-dialog"
                      value={values.deadline}
                      disablePast= {true}
                      onChange={(date) => {
                        setValues({ ...values, deadline: date });
                      }}
                      autoOk
                      KeyboardButtonProps = {{
                        'aria-label': 'change date',
                      }}
                    />
                </MuiPickersUtilsProvider>

                </Grid>
                <Grid item xs={6}>
                  <UploadInput
                    accept="image/png, image/jpeg"
                    id="upload-button-file"
                    type="file"
                    onChange={(e) => {
                      setValues({ ...values, image: e.target.files[0] });
                    }}
                  />
                  <label htmlFor="upload-button-file">
                    <Button
                      variant="contained"
                      color="secondary"
                      component="span"
                      startIcon={<UploadIcon/>}
                      fullWidth
                      size="large"
                      className={classes.uploadButton}
                    >
                      Upload Logo
                    </Button>
                  </label>

                  {values.image && <Typography>{values.image.name}</Typography>}
                  {errors.image && (
                    <Typography color="error">{errors.image}</Typography>
                  )}
                </Grid>
              </Grid>

              <Field
                component={TextField}
                name="amount"
                type="number"
                label="Payment Amount"
                fullWidth
                variant="outlined"
                mb={3}
              />
              <Grid item container  spacing={2}>
                <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<Payment/>}
              fullWidth
              size="large"
              onClick={handleClickOpen}
              className={classes.uploadButton}
            >
              Payment
            </Button>
                </Grid>
              </Grid>
              {/*<Field*/}
              {/*  component={TextField}*/}
              {/*  name="stripe"*/}
              {/*  type="text"*/}
              {/*  label="Stripe integration "*/}
              {/*  fullWidth*/}
              {/*  variant="outlined"*/}
              {/*  mb={3}*/}
              {/*/>*/}
              {/*<Button variant="outlined" color="primary" onClick={handleClickOpen}>*/}
              {/*  Open form dialog*/}
              {/*</Button>*/}

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
                fullWidth={isMobile}
              >
                Continue
              </Button>
            </Grid>

            {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}
          </StepCard>
        </Form>
      )}
    </Formik>

      <Dialog  open={open} fullWidth="true" maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle style={{background:"#7795f8",color:'white'}} id="form-dialog-title">Payment</DialogTitle>
    <DialogContent style={{background:"#7795f8"}}>
      <DialogContentText>
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <CheckoutForm />
        </Elements>
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{background:"#7795f8"}}>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
    </div>
  );
}
