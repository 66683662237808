import React from "react";
import {
  styled,
  Grid as MuiGrid,
  Button as MuiButton,
  useMediaQuery,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useHistory } from "react-router-dom";

import StepCard from "../../../layout/Step/StepCard";

const Grid = styled(MuiGrid)(spacing);
const Button = styled(MuiButton)(spacing);

export default function Registered() {
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const title = "Is your company already registered with Clear Contracts?";

  return (
    <StepCard innerTitle={!isMobile && title} title={isMobile && title}>
      <Grid
        container
        direction={isMobile ? "column" : "row"}
        justify="space-around"
        my={isMobile ? 0 : 6}
      >
        <Button
          variant="contained"
          color={isMobile ? "secondary" : "primary"}
          onClick={() => history.push("company-register/account-info")}
          mb={3}
        >
          Yes
        </Button>
        <Button
          variant="contained"
          color={isMobile ? "secondary" : "primary"}
          onClick={() =>
            history.push("company-register/account-info-not-registered")
          }
          mb={3}
        >
          No
        </Button>
      </Grid>
    </StepCard>
  );
}
