import React from "react";
import {
  makeStyles,
  styled,
  Button,
  Grid,
  Typography as MuiTypography,
  useMediaQuery,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import PlacesAutocomplete from "react-places-autocomplete";
import { useSelector, useDispatch } from "react-redux";

import StepCard from "../../../../layout/Step/StepCard";
import BaseFormikTextField from "../../../../../components/layout/forms/FormikTextField";
import TextField from "../../../../../components/layout/forms/TextField";
import { update } from "../../../../../redux/slices/influencerSlice";
import { getLocation } from "../../../../../utils/common";

const FormikTextField = styled(BaseFormikTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles(() => ({
  container: { height: 276 },
  button: { marginLeft: "auto", marginTop: 60, height: 38 },
  dropdownContainer: {
    marginTop: 8,
    zIndex: 500000,
    backgroundColor: "white",
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    borderRadius: 4,
  },
  menuItem: {
    padding: 12,
    cursor: "pointer",
    borderRadius: 4,
  },
  menuItemActive: {
    padding: 12,
    cursor: "pointer",
    borderRadius: 4,
    "&:hover": { backgroundColor: "#ccc" },
  },
}));

export default function GeneralData() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const userId = useSelector(({ auth }) => auth.user.id);
  const { age, city, state, country } = useSelector(
    ({ influencer }) => influencer
  );

  return (
    <Formik
      initialValues={{
        age: age || "",
        location: getLocation(city, state, country) || "",
        city: city || "",
        state: state || "",
        country: country || "",
      }}
      validationSchema={Yup.object({
        age: Yup.number()
          .max(120, "The age is too high")
          .min(0, "Must be a positive number")
          .required("Required"),
        location: Yup.string().required("Required"),
      })}
      onSubmit={async (
        { age, city, state, country },
        { setErrors, setStatus, setSubmitting }
      ) => {
        try {
          const response = await dispatch(
            update({
              profile_id: userId,
              data: {
                age,
                city,
                state,
                country,
              },
            })
          );

          if (
            response.type &&
            response.type === "influencer/update/fulfilled"
          ) {
            setSubmitting(false);
            history.push("details-data");
          }
        } catch (err) {
          setSubmitting(false);
          setStatus({ success: false });
          setErrors({ submit: "An error ocurred" });
        }
      }}
    >
      {({ isSubmitting, errors, values, setValues }) => (
        <Form>
          <StepCard title="Your Details" loading={isSubmitting}>
            <Grid container className={classes.container}>
              <Typography mb={6} mt={-4}>
                This information will help companies find your profile!
              </Typography>

              <Field
                component={FormikTextField}
                name="age"
                type="number"
                label="Age"
                fullWidth
                variant="outlined"
                mb={3}
              />

              <PlacesAutocomplete
                value={values.location}
                onChange={(city) => setValues({ ...values, location: city })}
                onSelect={(selectedCity) => {
                  const location = selectedCity.split(", ");
                  const city = location[0];
                  const state = location[2] ? location[1] : "";
                  const country = location[2]
                    ? location[2]
                    : location[1]
                    ? location[1]
                    : "";

                  setValues({
                    ...values,
                    location: selectedCity,
                    city,
                    state,
                    country,
                  });
                }}
                searchOptions={{ types: ["(cities)"] }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <>
                    <TextField
                      {...getInputProps({})}
                      variant="outlined"
                      label="Location"
                      fullWidth
                    />

                    {suggestions.length !== 0 && (
                      <div className={classes.dropdownContainer}>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? classes.menuItemActive
                            : classes.menuItem;

                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                              })}
                              key={suggestion.description}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </PlacesAutocomplete>

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
                fullWidth={isMobile}
              >
                Next
              </Button>
            </Grid>

            {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}
          </StepCard>
        </Form>
      )}
    </Formik>
  );
}
