import React from "react";
import { Button, CircularProgress, Divider, Grid, Hidden, makeStyles, styled, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ResponsiveCard from "../../layout/papers/ResponsiveCard";
import FormikTextField from "../../layout/forms/FormikTextField";
import { update } from "../../../redux/slices/influencerSlice";

const TextField = styled(FormikTextField)(spacing);

const useStyles = makeStyles({
  divider: { margin: "12px 0 24px" },
  title: { marginBottom: 32 },
});

export default function SocialsWidget({ member }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const userId = useSelector(({ auth }) => auth.user.id);
  const { instagram, twitter, snapchat, youtube, linkedin, facebook } =
    useSelector(({ influencer }) => influencer);

  return (
    <>
      <Hidden smUp>
        <Divider className={classes.divider}/>
      </Hidden>

      <ResponsiveCard>
        <Formik
          initialValues={{
            instagram: instagram || "",
            twitter: twitter || "",
            snapchat: snapchat || "",
            youtube: youtube || "",
            linkedin: linkedin || "",
            facebook: facebook || "",
          }}
          validationSchema={Yup.object({
            instagram: Yup.string(),
            twitter: Yup.string(),
            snapchat: Yup.string(),
            youtube: Yup.string(),
            linkedin: Yup.string(),
            facebook: Yup.string(),
          })}
          onSubmit={async (
            { instagram, twitter, snapchat, youtube, linkedin, facebook },
            { setErrors, setStatus, setSubmitting },
          ) => {
            try {
              const response = await dispatch(
                update({
                  profile_id: userId,
                  data: {
                    instagram,
                    twitter,
                    snapchat,
                    youtube,
                    linkedin,
                    facebook,
                  },
                }),
              );

              if (
                response.type &&
                response.type === "influencer/update/fulfilled"
              ) {
                setSubmitting(false);
                // if (instagram) {
                //   history.push("/influencer-edit-profile/general-data");
                // }
              }
            } catch (err) {
              setSubmitting(false);
              setStatus({ success: false });
              setErrors({ submit: "An error ocurred" });
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography variant="h6" className={classes.title}>
                    Socials
                  </Typography>
                </Grid>
                {!member && (
                  <Grid item>
                    <Button type="submit" variant="contained" size="small">
                      {isSubmitting ? (
                        <CircularProgress color="secondary"/>
                      ) : (
                        "Save changes"
                      )}
                    </Button>
                  </Grid>
                )}
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4} lg={3}>
                  <Field
                    component={TextField}
                    name="instagram"
                    type="text"
                    label="Instagram"
                    fullWidth
                    disabled={member}
                    mb={3}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <Field
                    component={TextField}
                    name="twitter"
                    type="text"
                    label="Twitter"
                    fullWidth
                    disabled={member}
                    mb={3}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <Field
                    component={TextField}
                    name="snapchat"
                    type="text"
                    label="Snapchat"
                    fullWidth
                    disabled={member}
                    mb={3}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <Field
                    component={TextField}
                    name="youtube"
                    type="text"
                    label="Youtube"
                    fullWidth
                    disabled={member}
                    mb={3}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <Field
                    component={TextField}
                    name="linkedin"
                    type="text"
                    label="Linkedin"
                    fullWidth
                    disabled={member}
                    mb={3}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <Field
                    component={TextField}
                    name="facebook"
                    type="text"
                    label="Facebook"
                    fullWidth
                    disabled={member}
                    mb={5}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ResponsiveCard>
    </>
  );
}
