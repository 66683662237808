import React, { useState, Fragment } from "react";
import {
  makeStyles,
  Card,
  Container,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100vw",
    height: "100vh",
    padding: 48,
    "@media (max-width:600px)": { padding: 0 },
  },
  button: {
    position: "absolute",
    top: 20,
    left: 20,
  },
  card: {
    position: "relative",
    height: "100%",
    backgroundColor: theme.palette.secondaryBackground,
    overflowY: "auto",
  },
  cardContent: {
    position: "relative",
    height: "100%",
    padding: "48px 0",
    "@media (max-width:600px)": {
      padding: 0,
    },
  },
}));

export default function Step({ backButton, children, manageSteps }) {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const Wrapper = isMobile
    ? Fragment
    : (props) => <Card className={classes.card} {...props} />;

  const [step, setStep] = useState(0);

  const handleNext = () => {
    step < children.length - 1 && setStep(step + 1);
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.container}>
      <Wrapper>
        {backButton && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleBack}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        )}
        <Container maxWidth="sm" className={classes.cardContent}>
          {manageSteps ? (
            <>
              {children.map(({ type: Child }, i) => (
                <Fragment key={i}>
                  {step === i && <Child handleNext={handleNext} />}
                </Fragment>
              ))}
            </>
          ) : (
            children
          )}
        </Container>
      </Wrapper>
    </div>
  );
}
