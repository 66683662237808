import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { postCall, putCall } from "../../utils/apicalls";
import { authenticate } from "./authSlice";
import { getCompany, getProfile } from "./companySlice";

const initialState = {
  status: "idle",
  error: "",
  id: "",
  name: "",
  email: "",
  image: ""
};

export const register = createAsyncThunk(
  "member/register",
  async (body, { dispatch }) => {
    const response = await postCall("/company_member/register", body);

    dispatch(
      authenticate({
        token: response.data.token,
        userType: "company-member",
        userId: response.id,
        userName: response.name,
        email: response.email,
      })
    );

    return response;
  }
);

export const login = createAsyncThunk(
  "member/login",
  async (body, { dispatch }) => {
    const response = await postCall("/company_member/login", body);

    await dispatch(getProfile(response.company_id));

    dispatch(
      authenticate({
        token: response.data.token,
        userType: "company-member",
        userId: response.id,
        userName: response.name,
        email: response.email,
      })
    );

    return response;
  }
);

export const getCompanyAndId = createAsyncThunk(
  "member/getCompanyAndId",
  async (code, { dispatch, getState }) => {
    const { payload: company } = await dispatch(getCompany(code));

    const { member } = getState();

    const response = await putCall(`/company_member/${member.id}`, {
      company_code: code,
      company_id: company.id,
    });

    return {
      company_member: response.returnCompany,
      company: company
    };
  }
);

const memberSlice = createSlice({
  name: "member",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(register.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.id = action.payload.id;
        state.email = action.payload.email;
        state.accessCode = action.payload.access_code;
        state.name = action.payload.name;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.id = action.payload.id;
        state.email = action.payload.email;
        state.accessCode = action.payload.access_code;
      })
      .addCase(getCompanyAndId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.name = action.payload.company.company_name;
        state.image = action.payload.company.image;
      });
  },
});

export default memberSlice.reducer;
