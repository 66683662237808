import React from "react";
import "./App.css";
import theme from "./Theme";
import { ThemeProvider } from "@material-ui/styles";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "../redux/store";

import Routes from "./Routes";

export const persistor = persistStore(store);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
