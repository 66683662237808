import React from "react";
import {
  makeStyles,
  styled,
  Button,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import Layout from "../../layout/Step/Step";
import StepCard from "../../layout/Step/StepCard";
import FormikTextField from "../../layout/forms/FormikTextField";
import { login as influencerLogin } from "../../../redux/slices/influencerSlice";
import { login as companyLogin } from "../../../redux/slices/companySlice";
import { login as memberLogin } from "../../../redux/slices/memberSlice";

const TextField = styled(FormikTextField)(spacing);

const useStyles = makeStyles(() => ({
  select: { width: "100%" },
  button: { margin: "auto", marginTop: 60,
    "@media (max-width: 970px)": {
      marginTop:30,
    }
  },
}));

function LoginForm({ company, member }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Required"),
        password: Yup.string()
          .min(8, "Must be at least 8 characters")
          .max(255)
          .required("Required"),
      })}
      onSubmit={async (values, { setErrors, setSubmitting, setStatus }) => {
        try {
          const login = company
            ? companyLogin
            : member
            ? memberLogin
            : influencerLogin;

          const response = await dispatch(
            login({
              email: values.email,
              password: values.password,
            })
          );

          if (
            response.type &&
            (response.type === "company/login/fulfilled" ||
              response.type === "member/login/fulfilled" ||
              response.type === "influencer/login/fulfilled")
          ) {
            history.push("/dashboard");
          } else {
            setErrors({ submit: "The username or password is incorrect" });
          }
        } catch (err) {
          setSubmitting(false);
          setStatus({ success: false });
          setErrors({ submit: "An error ocurred" });
        }
      }}
    >
      {({ isSubmitting, errors }) => (
        <Form>
          <StepCard title="Log in" loading={isSubmitting}>
            <Grid container>
              <Field
                component={TextField}
                name="email"
                type="text"
                label="Email Address"
                fullWidth
                variant="outlined"
                mb={3}
              />

              <Field
                component={TextField}
                name="password"
                type="password"
                label="Password"
                fullWidth
                variant="outlined"
                mb={3}
              />
              
              <Grid item xs={12}>
                {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}
              </Grid>

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
                fullWidth={isMobile}
              >
                Login
              </Button>

              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                fullWidth={isMobile}
                onClick={() => history.push("/influencer-register")}
              >
                Sign Up
              </Button>

              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                fullWidth={isMobile}
                onClick={() => history.push("/forgot-password")}
              >
                Forgot Password?
              </Button>
            </Grid>

            
          </StepCard>
        </Form>
      )}
    </Formik>
  );
}

export default function Login({ company, member }) {
  return (
    <Layout>
      <LoginForm company={company} member={member} />
    </Layout>
  );
}
