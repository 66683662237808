import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getCall, postCall } from "../../utils/apicalls";

const initialState = {
  status: "idle",
  error: "",
  conversation: {},
  conversations:{}
};

export const getConversation = createAsyncThunk(
  "conversation",
  async ( object ) => {
    const response = await getCall(`/getConversationMessages/${object.company_id}/${object.user_id}`);

    return response;
  }
);
export const getConversations = createAsyncThunk(
  "conversations",
  async ( body, { dispatch } ) => {
    const response = await postCall(`/getConversations`, body);

    return response;
  }
);
const conversationSlice = createSlice({
  name: "conversations",
  initialState,
  extraReducers: (builder) => {
    builder

      .addCase(getConversation.pending, (state, action) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getConversation.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          const data = action.payload;
          // const result = Object.values(data);
          state.conversation = data;

        }
      })  .addCase(getConversations.fulfilled, (state, action) => {
      state.status = "idle";
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        const data = action.payload;
        // const result = Object.values(data);
        state.conversations = data;

      }
    });

  },
});

export default conversationSlice.reducer;

