import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: "idle",
  error: "",
  token: "",
  user: { id: "", name: "", email: "", type: "" },
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticate(state, action) {
      state.status = "succeeded";
      state.token = action.payload.token;
      state.user.type = action.payload.userType;
      state.user.id = action.payload.userId;
      state.user.name = action.payload.userName;
      state.user.email = action.payload.email;
      state.user.image = action.payload.image;
      state.isAuthenticated = true;
    },
  },
});

export const { authenticate } = authSlice.actions;

export default authSlice.reducer;
