import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {  getCall } from "../../utils/apicalls";
import { getProfile, login, register, update } from "./influencerSlice";

const initialState = {
  status: "idle",
  error: "",
  influencers: [],
};

export const getAllInfluencers = createAsyncThunk(
  "influencers/getProfile",
  async () => {
    const response = await getCall(`/getAllUserProfiles`);

    return response;
  }
);
const influencersSlice = createSlice({
  name: "influencers",
  initialState,
  extraReducers: (builder) => {
    builder

      .addCase(getAllInfluencers.pending, (state, action) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getAllInfluencers.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload.error) {
          state.error = action.payload.error;
        } else {
          const data = action.payload;
          const result = Object.values(data);
          state.influencers = result;

        }
      });
  },
});

export default influencersSlice.reducer;

