import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { deepOrange } from "@material-ui/core/colors";
import {
  Grid,
  Hidden,
  Divider,
  Typography,
  Badge,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Popover,
  IconButton,
  Avatar, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction,
  Tooltip,
} from "@material-ui/core";
import {
  Notifications as NotificationsIcon,
  ChatBubble as ChatBubbleIcon,
  Explore as ExploreIcon,
  MonetizationOn as MonetizationOnIcon,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCall, postCall } from "../../../../utils/apicalls";
import { environment } from "../../../../environments/environment";
import DescriptionIcon from "@material-ui/icons/Description";

import ResponsiveCard from "../../../layout/papers/ResponsiveCard";
import { Launcher } from "react-chat-window";
import Layout from "../../../layout/Internal";
import io from "socket.io-client";

import { getConversation } from "../../../../redux/slices/conversation";
import { saveMessage } from "../../../../redux/slices/messageSlice";

const activityFeed = [
  {
    id: 0,
    type: "Message",
    from: "Nike",
    time: "2h",
  },
  { id: 1, type: "Contract", from: "Facebook", time: "6h" },
  {
    id: 2,
    type: "Expressed Interest",
    from: "Google",
    time: "1d",
  },
];

const useStyles = makeStyles((theme) => ({
  card: { padding: 24 },
  popover: { width: 1000, height: "100%" },
  divider: { margin: "24px 0" },
  messageTitle: { marginBottom: 12, marginLeft: 24, marginRight: 24, marginTop: 24, fontWeight: "bold",fontSize:25 },
  conversationTitle: { marginBottom: 24,textAlign:"center", marginTop: 24,fontSize:15},
  title: { marginBottom: 24 },
  iconsContainer: { paddingRight: 24 },
  icon: { padding: "4px 0" },
  buttonsContainer: { marginTop: 20 },
  row: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));
var abc = "";
export default function ActivityWidget() {
  const classes = useStyles();
  const history = useHistory();
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);

  const [messageCount, setMessageCount] = useState(0);
  const [messagesList, setMessagesList] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [conversationName, setConversationName] = useState("");
  const [conversationImage, setConversationImage] = useState("");

  const [contractCount, setContractCount] = useState(0);
  const [interestCount, setInterestCount] = useState(0);
  const userAuthId = useSelector(({ auth }) => auth.user.id);
  const { type } = useSelector(({ auth }) => auth.user);

  const getMembers = async () => {
    const response = await getCall(`/getNotifications/${userAuthId}`);
    setNotificationCount(response?.data?.length);
    setNotificationList(response?.data);
  };

  const getMessages = async () => {
    // const response = await getCall(`/getMessages/${userAuthId}`);
    // setMessagesList([]);
    // setMessageCount(response?.data?.length);
  };

  const getContracts = async () => {
    const response = await getCall(`/getContracts/${userAuthId}`);
    setContractCount(response?.data?.length);
  };

  const getInterestCount = async () => {
    const response = await getCall(`/getInterests/${userAuthId}`);
    console.log("response?.data?.length", response?.data?.length, response?.data);
    setInterestCount(response?.data?.length);
  };

  const getConversations = async () => {
    const response = await postCall("/getConversations", {
      id: userAuthId,
      user_type: type,
    });
    setConversations(response.conversations);
    setMessageCount(response.conversations.length);
  };
  useEffect(() => {
    getMembers();
    getMessages();
    getContracts();
    getInterestCount();
    getConversations();
  }, []);


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userId, setUserId] = React.useState(null);
  const [companyId, setCompanyId] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [messages, setMessages] = useState([]);
  const [showLauncher, setShowLauncher] = useState(false);
  const [launcherOpen, setlauncherOpen] = useState(false);
  const [yourID, setYourID] = useState();
  const userType = useSelector(({ auth }) => auth.user.type);
  const dispatch = useDispatch();

  const conversation = useSelector(({ conversation }) => conversation);

  const user = useSelector(({ auth }) => auth.user);
  const socketRef = useRef();
  useEffect(() => {
    socketRef.current = io.connect(environment.socketUrl);

    socketRef.current.on("your id", id => {
      abc = id;
      setYourID(id);
    });

    socketRef.current.on("message", (message) => {
      _sendMessage(message);
    });
  }, []);
  useEffect(() => {
    if (conversation.conversation.conversation) {
      setMessages([]);
      let msgs = conversation.conversation.conversation.ConversationReplies;
      console.log(conversation.conversation.conversation);
      if (type == "company") {
        setConversationName(conversation.conversation.conversation.User.name);
        setConversationImage(conversation.conversation.conversation.User.image);
      } else {
        setConversationName(conversation.conversation.conversation.Company.company_name);
        setConversationImage(conversation.conversation.conversation.Company.image);
      }
      msgs.map((message) => {
        let obj = {
          text: message.reply,
          // id: yourID,
        };
        let author = "";
        if (userType === "company") {
          if (message.company_id) {
            author = "me";
          } else {
            author = "them";
          }
        } else {
          if (message.user_id) {
            author = "me";
          } else {
            author = "them";
          }
        }
        const messageObject2 = {
          author: author,
          type: "text",
          data: obj,
        };
        setMessages(oldMsgs => [...oldMsgs, messageObject2]);
      });
    }
  }, [conversation]);

  function _onMessageWasSent(message) {
    let obj = {
      text: message.data.text,
      id: yourID,
      company_id: companyId,
      user_id: userId,
    };
    const messageObject2 = {
      author: "me",
      type: "text",
      data: obj,
    };
    socketRef.current.emit("send message", messageObject2);
    const body = {
      data: {
        company_id: companyId,
        user_id: userId,
        sender: userType,
        reply: message.data.text,
      },
    };
    dispatch(saveMessage(body));


  }

  function _sendMessage(text) {
    if (userType === "influencer") {
      if (user.id == text.data.user_id) {
        setShowLauncher(true);
        setUserId(text.data.user_id);
        setCompanyId(text.data.company_id);
        let object = {
          company_id: text.data.company_id,
          user_id: text.data.user_id,
        };
        dispatch(getConversation(object));
        if (text.data.id === abc) {
          const messageObject2 = {
            author: "me",
            type: "text",
            data: text.data,
          };
          setMessages(oldMsgs => [...oldMsgs, messageObject2]);

        } else {
          const messageObject2 = {
            author: "them",
            type: "text",
            data: text.data,
          };
          setMessages(oldMsgs => [...oldMsgs, messageObject2]);

        }
      }
    } else {
      if (user.id == text.data.company_id) {
        setShowLauncher(true);
        setUserId(text.data.user_id);
        setCompanyId(text.data.company_id);
        let object = {
          company_id: text.data.company_id,
          user_id: text.data.user_id,
        };
        dispatch(getConversation(object));
        if (text.data.id === abc) {
          const messageObject2 = {
            author: "me",
            type: "text",
            data: text.data,
          };
          setMessages(oldMsgs => [...oldMsgs, messageObject2]);

        } else {
          const messageObject2 = {
            author: "them",
            type: "text",
            data: text.data,
          };
          setMessages(oldMsgs => [...oldMsgs, messageObject2]);

        }
      }
    }


  }

  function getTimeDiff( join)
  {
      let milliseconds , time = '';
      let t1 = new Date(join).getTime(), t2 = Date.now();
      if( isNaN(t1) || isNaN(t2) ) return '';
      if (t1 < t2) milliseconds = t2 - t1; else milliseconds = t1 - t2;
      var days = Math.floor(milliseconds / 1000 / 60 / (60 * 24));
      var date_diff = new Date( milliseconds );
      if (days > 0) time += days + 'd ';
      if (date_diff.getHours() > 0) time += date_diff.getHours() + 'h ';
      // if (date_diff.getMinutes() > 0) time += date_diff.getMinutes() + 'm ';
      // if (date_diff.getSeconds() > 0) time += date_diff.getSeconds() + 's ';
      return time;
  }
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const listClick = (company_id, user_id, user, company) => {
    setAnchorEl(null);
    setShowLauncher(true);
    setUserId(user_id);
    setCompanyId(company_id);

    if (type == "company") {
      setConversationName(user.name);
      setConversationImage(user.image);
    } else {
      setConversationName(company.company_name);
      setConversationImage(company.image);
    }

    let object = {
      company_id,
      user_id,
    };
    dispatch(getConversation(object));
  };
  const launcherClick = (e) => {
    // setShowLauncher(false);
    setlauncherOpen(launcherOpen == true? false : true);
  };

  return (
    <ResponsiveCard>
      <Hidden smUp>
        <Divider className={classes.divider}/>
      </Hidden>

      <Typography variant="h4" className={classes.title}>
        Notifications
      </Typography>

      <Grid container>
        <Hidden smDown>
          <Grid
            container
            direction="column"
            item
            xs={2}
            className={classes.iconsContainer}
          >
            <Grid container justify="center" className={classes.icon}>
              <Tooltip title="Notifications">
                <IconButton onClick={() => history.push("/notifications")}>
                  <Badge badgeContent={notificationCount} color="primary">
                    <NotificationsIcon/>
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid container justify="center" className={classes.icon}>
              <Tooltip title="Chat">

                <IconButton onClick={(e) => handleClick(e)}>
                  <Badge badgeContent={messageCount} color="primary">
                    <ChatBubbleIcon/>
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid container justify="center" className={classes.icon}>
              <Tooltip title="Explore Members">
                <IconButton onClick={() => history.push("/members")}>
                  <Badge badgeContent={interestCount} color="primary">
                    <ExploreIcon/>
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid container justify="center" className={classes.icon}>
              <Tooltip title="Contracts">
                <IconButton onClick={() => history.push("/view-contracts")}>
                  <Badge badgeContent={contractCount} color="primary">
                    <DescriptionIcon/>
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Hidden>

        <Grid item xs={12} sm={10}>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="notifications table">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell align="left">From</TableCell>
                  <TableCell align="left">Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notificationList.slice(0, 3).map((row) => (
                  <TableRow
                    key={row.id}
                    onClick={() => history.push("/notifications")}
                    className={classes.row}
                  >
                    <TableCell component="th" scope="row">
                      {row.type === null ? 'Contract' : 'Interest'}
                    </TableCell>
                    <TableCell align="left"><div dangerouslySetInnerHTML={{ __html: row.text }} /></TableCell>
                    <TableCell align="left">{getTimeDiff(row.updatedAt)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid
          container
          spacing={1}
          justify="center"
          className={classes.buttonsContainer}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/notifications")}
            >
              Notifications
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/view-contracts")}
            >
              Your contracts
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 10000, left: 1000 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Typography variant="h5" className={classes.messageTitle}>
          Messenger
        </Typography>

        {
          conversations.length != 0 ?

            conversations.map((item, i) => (
              <List style={{ "cursor": "pointer" }} key={i} dense
                    onClick={() => listClick(item.company_id, item.user_id, item.User, item.Company)}>
                <ListItem className={classes.item}>
                  <ListItemAvatar>
                    {
                      type != "company" ?
                        item.Company.image ?
                          <Avatar alt={item.Company.company_name} src={item.Company.image}/> :
                          <Avatar>{item.Company.company_name}</Avatar> : item.User.image ?
                        <Avatar alt={item.User.name} src={item.User.image}/> :
                        <Avatar>{item.User.name}</Avatar>
                    }

                  </ListItemAvatar>

                  <ListItemText primary={type != "company" ? item.Company.company_name : item.User.name}/>
                  <ListItemSecondaryAction>

                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            )) :  <Typography variant="h6" className={classes.conversationTitle}>
              No Conversations
            </Typography>
        }
      </Popover>
      {
        <Launcher
          agentProfile={{
            teamName: conversationName,
            // imageUrl: <Avatar src={conversationImage}></Avatar>,
          }}
          onMessageWasSent={_onMessageWasSent}
          messageList={messages}
          isOpen={launcherOpen}
          handleClick={launcherClick}
          showEmoji
        />
      }

    </ResponsiveCard>
  );
}
