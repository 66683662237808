import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Layout from "../../layout/Step/Step";
import ConfirmLogin from "../login/ConfirmLogin";
import GeneralData from "../register/influencer/steps/GeneralData";
import DetailsData from "../register/influencer/steps/DetailsData";

export default function InfluencerRegister() {
  const match = useRouteMatch();

  return (
    <Layout backButton>
      <Switch>
        <Route exact path={`${match.path}`}>
          <ConfirmLogin />
        </Route>
        <Route path={`${match.path}/general-data`}>
          <GeneralData />
        </Route>
        <Route path={`${match.path}/details-data`}>
          <DetailsData />
        </Route>
      </Switch>
    </Layout>
  );
}
