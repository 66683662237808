import React, { useEffect } from "react";
import {
  Hidden,
  useMediaQuery,
  makeStyles,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import defaultImage from "../../../../../assets/images/default-profile-picture.png";
import { getProfile } from "../../../../../redux/slices/influencerSlice";
import { getLocation } from "../../../../../utils/common";
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(() => ({
  imageContainer: {
    "@media (max-width: 600px)": { order: -1 },
  },
  image: { height: 260, width: 220, maxWidth: "100%", objectFit: "cover" },
  buttonsContainer: { flexGrow: 1, paddingTop: 28 },
}));

export default function InfluencerProfileInfo() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const userId = useSelector(({ auth }) => auth.user.id);
  const { city, state, country, bio, image, name, status } = useSelector(
    ({ influencer }) => influencer
  );

  useEffect(() => {
    dispatch(getProfile(userId));
  }, [dispatch, userId]);

  return (
    <>
      {status == "loading" &&
        <div className={classes.loading}>
          <LinearProgress />
        </div>
      }

      {status != "loading" &&
        <Grid container>
          <Grid
            container
            justify={isMobile ? "center" : "flex-start"}
            item
            md={5}
            className={classes.imageContainer}
          >
            <img src={image ? image!=='/assets/images/default.png'?image:defaultImage : defaultImage} alt="" className={classes.image} />
          </Grid>
          <Grid container direction="column" item md={7}>
            <Hidden smDown>
              <Typography variant="h6">
                {name}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {getLocation(city, state, country)}
              </Typography>
              <Typography gutterBottom>{bio}</Typography>
            </Hidden>

            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              justify={isMobile ? "center" : "flex-start"}
              className={classes.buttonsContainer}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size={isMobile ? "small" : "medium"}
                  onClick={() => history.push("/profile")}
                >
                  View Profile
            </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  size={isMobile ? "small" : "medium"}
                  onClick={() =>
                    history.push("/influencer-edit-profile/general-data")
                  }
                >
                  Edit profile
            </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    </>
  );
}
