import React, { useEffect } from "react";
import {
  makeStyles,
  Grid,
  useMediaQuery,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import defaultImage from "../../../../../assets/images/default-profile-picture.png";

import { getLatest } from "../../../../../redux/slices/dashboardSlice";

const useStyles = makeStyles(() => ({
  container: { marginTop: 32 },
  title: { marginTop: 32 },
  item: { "@media (max-width: 600px)": { paddingLeft: 0, paddingRight: 140 } },
  exploreButton: { marginTop: 20 },
  image: { width: 40, height: 40, borderRadius: "100%"},
}));

export default function ActivityFeed() {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();

  const { users } = useSelector(
    ({ dashboard }) => dashboard
  );

  useEffect(() => {
    dispatch(getLatest());
  }, []);

  return (
    <div className={classes.container}>
      <Divider />

      <Typography variant="h5" className={classes.title}>
        Activity Feed
      </Typography>
      { typeof users !== 'undefined' && users.length !== 0 && Object.values(users).map((item, i) => (
        <List key={i} dense>
          <ListItem className={classes.item}>
            <ListItemAvatar>
              <img src={item.image ? item.image!=='/assets/images/default.png'?item.image:defaultImage : defaultImage} className={classes.image} alt="" />
            </ListItemAvatar>
            <ListItemText primary={`${item.user_type == 'influencer' ? item.name : item.company_name} signed up`} />
            <ListItemSecondaryAction>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => history.push(`/member-profile/${item.id}`)}
              >
                View profile
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      ))}

      <Grid container justify={isMobile ? "center" : "flex-start"}>
        <Button
          variant="contained"
          color="primary"
          className={classes.exploreButton}
          onClick={() => history.push("/members")}
        >
          Explore members
        </Button>
      </Grid>
    </div>
  );
}
