import React, { Fragment } from "react";
import { makeStyles, useMediaQuery, Card } from "@material-ui/core";

const useStyles = makeStyles({ card: { padding: 24 } });

export default function ResponsiveCard({ children }) {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const Wrapper = isMobile
    ? Fragment
    : (props) => <Card {...props} className={classes.card} />;

  return <Wrapper>{children}</Wrapper>;
}
