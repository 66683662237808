import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#E9EFF5",
      main: "#BFECFF",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      main: "#86D883",
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    error: {
      main: "#E74C3C",
    },
    warning: {
      main: "#E74C3C",
    },
    info: {
      light: "#ccf2f433",
      main: "#ccf2f4",
    },
    success: {
      light: "#a4ebf333",
      main: "#a4ebf3",
    },
    secondaryBackground: "#F7F7F7",
    tertiaryBackground: "#E9F4FE",
    label: { main: "rgba(0, 0, 0, 0.87)" },
    // error: will use the default color
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    // contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },
  // typography: {
  //   fontFamily: 'Trade Gothic',
  // },
  overrides: {
    // Style sheet name ⚛️
    MuiSvgIcon: {
      // Name of the rule
      colorAction: {
        // Some CSS
        color: "#89cff0",
      },
      fontSizeLarge: {
        fontSize: "44px",
      },
    },
    MuiTypography: {
      h1: {
        marginBottom: "18px",
        fontSize: "4.5rem",
        fontWeight: 300,
      },
      h2: {
        marginBottom: "16px",
        fontSize: "3.25rem",
      },
      h3: {
        fontSize: "2.5rem",
        fontWeight: 400,
      },
      h4: {
        marginBottom: "12px",
        fontSize: "2rem",
      },
      h5: {
        marginBottom: "10px",
        fontSize: "1.65rem",
        lineHeight: 1.1,
      },
      h6: {
        fontSize: "1.25rem",
        fontWeight: 500,
      },
    },
  },
  // Custom classes
  deptProjectsHeader: {
    width: "100%",
    paddingBottom: "24px",
  },
});

export default theme;
