import React, { useState, useEffect } from "react";
import {
  makeStyles,
  styled,
  Button,
  Grid as MuiGrid,
  Typography as MuiTypography,
  useMediaQuery,
  Card,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle

} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCall, postCall } from "../../../utils/apicalls";
import defaultImage from "../../../assets/images/default-profile-picture.png";
import { getContract } from "../../../redux/slices/contractSlice";
import Moment from "react-moment";

import Layout from "../../layout/Internal";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: "auto",
    marginTop: 20,
  },
  uploadButton: { height: 56 },
  card: { padding: 48 },
  image: {
    height: 200,
    width: 200,
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  },
  profileimage: {
    height: 40,
    width: 40,
    borderRadius: '100%'
  },
  dialogButton: { margin: 4, marginBottom: 12 },
}));

export default function ContractSummary() {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { status } = useParams();
  const {pathname} = useLocation();
  // const contractInfo = useSelector(({ contract }) => contract);
  const { type } = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();
  const userId = useSelector(({ auth }) => auth.user.id);
  let contractId = pathname.replace('/contract/', '').replace('finalize/', '');
  // const contractInfo = useSelector(({ contract }) => contract);

  const Wrapper = isMobile
    ? (props) => <div {...props} />
    : (props) => <Card {...props} className={classes.card} />;

  const handleClick =async (status_Val) => {
    // history.push("/dashboard");
      const responsedata = await postCall(`/updateContract`, {
        contract_id: contractId,
        data: { status: status_Val },
      });
      history.push("/dashboard");

  };
  const checkBoxClick=(event)=>{
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const [contractInfo, setContractInfo] = useState({});

  const getContractFun = async () => {
    const response = await dispatch(getContract(contractId));
    setContractInfo(response?.payload);
  };

  const handleRenegotiateClick = () => {
    history.push({pathname: "/new-contract", state: {
      type: 'edit', contractId: contractId, influencerId: contractInfo.player_id
    }});
  };

  const handleMarkAsCompleted = async () => {
    const responsedata = await postCall(`/updateContract`, {
      contract_id: contractId,
      data: { status: 'completed' },
    });

    toggleOpen();
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const displayDate = (date) => {
    return (
      date === null ? "" :
        <>
          <Moment format="MM/DD/YYYY">
            {date}
          </Moment>
        </>)
  }

  useEffect(() => {
    getContractFun();
  }, [dispatch]);
  //End - Dialog

  return (
    <>
      <Layout>
        <Typography variant="h4" my={2}>
          Contract Summary
        </Typography>
        <Wrapper>
          <Grid container direction="column" mt={isMobile ? 4 : 0}>
            <Typography variant="h6">Description</Typography>
            <Typography mb={3}>
              {contractInfo.description}
            </Typography>
          </Grid>

          <Grid
            container
            direction={isMobile ? "column" : "row"}
            justify="space-between"
            mb={3}
            spacing={1}
          >
            <Grid item>
              <Typography variant="h6">Deadline</Typography>
              <Typography>{displayDate(contractInfo.deadline)}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">Other Party</Typography>
              <Typography>{type == "company" ? contractInfo.player_name : contractInfo.company_name}</Typography>
              {console.log('contractInfo.Company', contractInfo, Object.keys(contractInfo).length)}
              {Object.keys(contractInfo).length !== 0 && 
                (type == "company" ? 
                <img src={contractInfo.Player.image ? contractInfo.Player.image!=='/assets/images/default.png'?contractInfo.Player.image:defaultImage : defaultImage} alt="" className={classes.profileimage} /> 
                : <img src={contractInfo.Company.image ? contractInfo.Company.image!=='/assets/images/default.png'?contractInfo.Company.image:defaultImage : defaultImage} alt="" className={classes.profileimage} />
             ) }
            </Grid>
            <Grid item>
              <Typography variant="h6">Payment</Typography>
              <Typography>{contractInfo.payment_amount}</Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={4} container direction="column" mb={5}>
              <Typography variant="h6" gutterBottom>
                Post Media Included
              </Typography>
              <img src={contractInfo.image ? contractInfo.image :defaultImage} className={classes.image} />
            </Grid>

            <Grid
              item
              xs={12}
              md={8}
              container
              justify={isMobile ? "flex-start" : "flex-end"}
              alignItems="flex-end"
              alignContent="flex-end"
            >
              {contractInfo.status === "finalize" && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClick('finalize')}
                >
                  Go Home
                </Button>
              )}

              {contractInfo.status === "completed" && (
                <Typography variant="h4" color="secondary">
                  This contract is completed
                </Typography>
              )}

              {type == "company" && contractInfo.status === "active" && (
                <Grid
                  container
                  direction="column"
                  alignItems={isMobile ? "flex-start" : "flex-end"}
                >
                  <Typography variant="h4" color="secondary">
                    This contract is active
                  </Typography>

                  <Typography my={1}>
                    Please click this button if you claim this deal got
                    completed
                  </Typography>

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={toggleOpen}
                  >
                    Confirm contract terms were completed correctly
                  </Button>
                </Grid>
              )}

              {type !== "company" && contractInfo.status === "pending" && (
                <Grid
                  container
                  spacing={2}
                  justify={isMobile ? "flex-start" : "flex-end"}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleRenegotiateClick}
                    >
                      Renegotiate
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleClick('active')}
                    >
                      Accept
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Wrapper>
      </Layout>

      <Dialog open={open} onClose={toggleOpen}>
        <DialogTitle>Are you sure?</DialogTitle>
        <Grid container justify="center" className={classes.buttonsContainer}>
          <Button
            onClick={handleMarkAsCompleted}
            color="secondary"
            variant="contained"
            className={classes.dialogButton}
          >
            Yes
          </Button>

          <Button
            onClick={toggleOpen}
            color="secondary"
            variant="contained"
            className={classes.dialogButton}
          >
            No
          </Button>
        </Grid>
      </Dialog>
    </>
  );
}
