import React from "react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Container from "@material-ui/core/Container";
import Tooltip from '@material-ui/core/Tooltip';

import DashboardIcon from "@material-ui/icons/Dashboard";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import GroupIcon from '@material-ui/icons/Group';
import DescriptionIcon from "@material-ui/icons/Description";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import logo from "../../assets/images/cc_logo_text_mid.png";
import { persistor } from "../App";

var links = [
  {
    text: "Dashboard",
    link: "/dashboard/",
    icon: <DashboardIcon />,
  },
  {
    text: "Activity",
    link: "/notifications/",
    icon: <NotificationsActiveIcon />,
  },
  {
    text: "Members",
    link: "/members/",
    icon: <GroupIcon />,
  },
  {
    text: "Contracts",
    link: "/view-contracts",
    icon: <DescriptionIcon />,
  },
  {
    text: "Profile",
    link: "/profile/",
    icon: <AccountCircleIcon />,
  },
  {
    text: "Logout",
    link: "/",
    action: () => {
      persistor.purge();
      window.location.href = "/";
    },
    icon: <ExitToAppIcon />,
  },
];

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: theme.palette.info.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "@media (max-width: 600px)": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  logo: { maxHeight: 60, padding: 12 },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerToolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: "100px 8px 20px",
  },
  mobileDrawer: {
    width: 0,
    flexShrink: 0,
  },
  mobileDrawerPaper: {
    width: drawerWidth,
  },
  mobileDrawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export default function Internal({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="inherit"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <a href="/#/dashboard/">
            {!isMobile && <img src={logo} alt="logo" className={classes.logo} />}
          </a>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? "persistent" : "permanent"}
        className={
          isMobile
            ? classes.mobileDrawer
            : clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })
        }
        anchor="left"
        open={open}
        classes={{
          paper: isMobile
            ? classes.mobileDrawerPaper
            : clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
        }}
      >
        <div
          className={
            isMobile ? classes.mobileDrawerHeader : classes.drawerToolbar
          }
        >
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {links.map((link, index) => (
              <ListItem
                button
                key={link.text + index}
                component={NavLink}
                to={link.link}
                onClick={link.action && link.action}
              >
                <Tooltip title={link.text} aria-label={link.text} key={link.text + index}>
                  <ListItemIcon>{link.icon}</ListItemIcon>
                </Tooltip>

                  <ListItemText>{link.text}</ListItemText>
              </ListItem>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <Container maxWidth="lg"> {children}</Container>
      </main>
    </div>
  );
}
