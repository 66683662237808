import React from "react";
import { makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import Layout from "../../layout/Step/Step";
import logo from "../../../assets/images/cc_logo_text_mid.png";

const useStyles = makeStyles({
  container: { height: "100%" },
  logo: {
    maxWidth: "calc(100% - 24px)",
    padding: 12,
    "@media (max-width: 600px)": {
      marginTop: 80,
    },
  },
});

export default function Register() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Layout backButton>
      <Grid
        container
        direction="column"
        justify="space-around"
        className={classes.container}
      >
        <img src={logo} alt="logo" className={classes.logo} />

        <Typography variant="h3" align="center">
          Create Account For
        </Typography>

        <Grid container justify="space-around">
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/influencer-register")}
          >
            Influencer
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/company-register")}
          >
            Company
          </Button>
        </Grid>
      </Grid>
    </Layout>
  );
}
