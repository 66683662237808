import React from "react";
import { Container } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import LockIcon from "@material-ui/icons/Lock";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import FastForwardIcon from "@material-ui/icons/FastForward";
import DnsIcon from "@material-ui/icons/Dns";

import LandingAuth from "./sections/LandingAuth";
import LandingHero from "./sections/LandingHero";
import LandingMission from "./sections/LandingMission";
import LandingFeatures from "./sections/LandingFeatures";

export default function LandingCompany(props) {
  if (props.isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  const features = [
    {
      Icon: HourglassEmptyIcon,
      title: "Save you time and money",
      description: `We save you time and money by automating parts of the contract process 
        that make it inconvenient, like finding influencers worth the money, 
        and increasing your network so you can boost your brand.`,
    },
    {
      Icon: LockIcon,
      title: "Secure contract execution",
      description: `Our software integrates with Stipe technology for secure money transfers
      that you can trust. Money doesn’t not get sent until you approve.`,
    },
    {
      Icon: CompareArrowsIcon,
      title: "Creation of a two-sided marketplace",
      description: `Our platform creates an ecosystem that connects people who want to
      do business together and then makes contracts easy.`,
    },
    {
      Icon: FastForwardIcon,
      title: "Fast, simple contract creation",
      description: `We provide easy and streamlined contract creation.`,
    },
    {
      Icon: DnsIcon,
      title: "Efficient Organization",
      description: `We organize your contracts all in one place in a way that will never confuse you.`,
    },
  ];

  return (
    <>
      <LandingAuth company />
      <Container maxWidth="md">
        <LandingHero
          text="Making your contracts more transparent, efficient, and secure."
          company
        />
        <LandingMission />
        <LandingFeatures features={features} />
      </Container>
    </>
  );
}
