import React from "react";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import Layout from "../../layout/Step/Step";
import logo from "../../../assets/images/cc_logo_text_mid.png";

const useStyles = makeStyles({
  container: {
    height: "100%",

  },

  subContainer: {
    "@media (max-width: 700px)": {
      width: "90%",
    },

  },
  logo: {
    maxWidth: "calc(100% - 24px)",
    padding: 12,
    "@media (max-width: 600px)": {
      marginTop: 80,
    },
  },
});

export default function Landing() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Layout>
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"

        className={classes.container}
      >
        <Grid className={classes.subContainer}>
          <img src={logo} alt="logo" className={classes.logo}/>

          <Typography variant="h6" align="center">
            Connecting influencers and brands like never before.
          </Typography>
        </Grid>

        <Grid container justify="center" spacing={4} className={classes.subContainer}>
          <Grid item>
            <Typography variant="h3" align="center">
              Which are you?
            </Typography>
          </Grid>
          <Grid container justify="space-around" item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/influencer")}
            >
              Influencer
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/company")}
            >
              Company
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </Layout>
  );
}
