import React from "react";
import { Grid } from "@material-ui/core";

import Layout from "../../layout/Internal";
import ProfileWidget from "./widgets/profile/ProfileWidget";
import InfluencerProfileInfo from "./widgets/profile/InfluencerProfileInfo";
import ActivityFeed from "./widgets/profile/ActivityFeed";
import NotificationsWidget from "./widgets/NotificationsWidget";
import AccountSetupWidget from "./widgets/AccountSetupWidget";

export default function InfluencerDashboard() {
  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item md={7}>
          <ProfileWidget>
            <InfluencerProfileInfo />
            <ActivityFeed />
          </ProfileWidget>
        </Grid>

        <Grid container direction="column" spacing={3} item md={5}>
          <Grid item>
            <NotificationsWidget />
          </Grid>
          <Grid item>
            <AccountSetupWidget />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}
