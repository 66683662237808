import React from "react";
import {
  makeStyles,
  styled,
  Button,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import StepCard from "../../layout/Step/StepCard";
import FormikTextField from "../../layout/forms/FormikTextField";

const TextField = styled(FormikTextField)(spacing);

const useStyles = makeStyles(() => ({
  select: { width: "100%" },
  button: { margin: "auto", marginTop: 60 },
}));

export default function ConfirmLogin() {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Required"),
        password: Yup.string()
          .min(8, "Must be at least 8 characters")
          .max(255)
          .required("Required"),
      })}
      onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          history.push("/influencer-edit-profile/general-data");
        }, 1000);
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <StepCard title="Log in" loading={isSubmitting}>
            <Grid container>
              <Field
                component={TextField}
                name="email"
                type="text"
                label="Email Address"
                fullWidth
                variant="outlined"
                mb={3}
              />

              <Field
                component={TextField}
                name="password"
                type="password"
                label="Password"
                fullWidth
                variant="outlined"
                mb={3}
              />

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
                fullWidth={isMobile}
              >
                Login
              </Button>
            </Grid>

            {errors.submit && <Alert severity="warning">{errors.submit}</Alert>}
          </StepCard>
        </Form>
      )}
    </Formik>
  );
}
