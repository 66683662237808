import React from "react";
import {
  makeStyles,
  styled,
  Grid,
  Typography,
  Button as MuiButton,
  useMediaQuery,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import defaultImage from "../../../../../assets/images/default-profile-picture.png";

import StepCard from "../../../../layout/Step/StepCard";

const Button = styled(MuiButton)(spacing);
const useStyles = makeStyles({
  container: { position: "relative" },
  image: {
    height: 100,
    width: 100,
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "@media (max-width: 600px)": {
      position: "relative",
      top: "initial",
      left: "initial",
    },
  },
});
export default function ConfirmCompany() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const history = useHistory();
  const company = useSelector(({ member }) => member);
  const classes = useStyles();

  return (
    <StepCard title="Confirm Your Company">
      <Grid container direction="column" alignItems="center">
        <Typography variant="h4">{company.name}</Typography>
        
        <img src={company.image ? company.image!=='/assets/images/default.png'?company.image:defaultImage : defaultImage} alt="" className={classes.image} />
        
        <Button
          variant="contained"
          color="secondary"
          mt={4}
          fullWidth={isMobile}
          onClick={() => history.push("/dashboard")}
        >
          Confirm
        </Button>
        <Button mt={1}>Not my company</Button>
      </Grid>
    </StepCard>
  );
}
