import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import logo from "../../../../assets/images/cc_logo_text_mid.png";

const useStyles = makeStyles((theme) => ({
  container: { marginTop: 40 },
  logo: { maxWidth: "100%", margin: "auto" },
}));

export default function LandingHero({ text, company }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container justify="center" className={classes.container} spacing={3}>
      <Grid item>
        <img src={logo} alt="logo" className={classes.logo} />
      </Grid>
      <Grid item>
        <Typography variant="h5" align="center">
          {text}
        </Typography>
      </Grid>
      <Grid container spacing={6} justify="center" item>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/register")}
          >
            Signup
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(company ? "/company-owner-login" : "/login")}
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
